import {
  ACCOUNT_FETCHED,
  UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE,
  ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE_UPDATED,
  CLEAN_ACCOUNT
} from '../actions/account'

import { createReducer } from '../utils'

const initialState = {
  account: {},
  usage: {
    charactersCount: 0,
    seconds: 0
  },
  isSaving: false
}

const handlers = {
  [ACCOUNT_FETCHED]: (state, action) => ({
    ...state,
    account: !action.error ? action.account : {}
  }),
  [UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE]: (state, action) => ({
    ...state,
    isSaving: true
  }),
  [ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE_UPDATED]: (state, action) => ({
    ...state,
    isSaving: false,
    account: !action.error ? action.account : state.account
  }),
  [CLEAN_ACCOUNT]: (state, action) => initialState
}

export default createReducer(handlers, initialState)
