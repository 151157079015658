import { Fragment, Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'

import Navbar from '../components/Navbar'

import { fetchCognitoUser } from '../actions/user'
import { getCognitoUser } from '../reducers/selectors'

export default withRouter(({ route }) => {
  const dispatch = useDispatch()
  const cognitoUser = getCognitoUser()

  useEffect(() => {
    if (!cognitoUser.hasOwnProperty('username')) {
      dispatch(fetchCognitoUser())
    }
  }, [cognitoUser.username])

  return (
    <Fragment>
      <Suspense fallback={<span />}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<span />}>{renderRoutes(route.routes)}</Suspense>
    </Fragment>
  )
})
