import { makeActionCreator } from '../utils'

export const CLEAN_JOB = 'CLEAN_JOB'
export const cleanJob = makeActionCreator(CLEAN_JOB)

export const CLEAN_JOBS = 'CLEAN_JOBS'
export const cleanJobs = makeActionCreator(CLEAN_JOBS)

export const SET_JOB = 'SET_JOB'
export const setJob = makeActionCreator(SET_JOB, 'job')

export const FETCH_JOBS = 'FETCH_JOBS'
export const JOBS_FETCHED = 'JOBS_FETCHED'
export const fetchJobs = makeActionCreator(FETCH_JOBS, 'params')
export const jobsFetched = makeActionCreator(JOBS_FETCHED, 'jobs', 'error')

export const FETCH_JOB = 'FETCH_JOB'
export const JOB_FETCHED = 'JOB_FETCHED'
export const fetchJob = makeActionCreator(FETCH_JOB, 'params')
export const jobFetched = makeActionCreator(JOB_FETCHED, 'job', 'error')

export const RESET_JOB_TRANSCRIPT = 'RESET_JOB_TRANSCRIPT'
export const JOB_TRANSCRIPT_RESET = 'JOB_TRANSCRIPT_RESET'
export const resetJobTranscript = makeActionCreator(
  RESET_JOB_TRANSCRIPT,
  'params'
)
export const jobTranscriptReset = makeActionCreator(
  JOB_TRANSCRIPT_RESET,
  'job',
  'error'
)

export const START_TRANSLATION = 'START_TRANSLATION'
export const TRANSLATION_STARTED = 'TRANSLATION_STARTED'
export const startTranslation = makeActionCreator(START_TRANSLATION, 'params')
export const translationStarted = makeActionCreator(
  TRANSLATION_STARTED,
  'job',
  'error'
)

export const TRANSLATION_FINISHED = 'TRANSLATION_FINISHED'
export const translationFinished = makeActionCreator(TRANSLATION_FINISHED)

export const UPDATE_JOB_NAME = 'UPDATE_JOB_NAME'
export const JOB_NAME_UPDATED = 'JOB_NAME_UPDATED'
export const updateJobName = makeActionCreator(UPDATE_JOB_NAME, 'params')
export const jobNameUpdated = makeActionCreator(
  JOB_NAME_UPDATED,
  'job',
  'error'
)

export const UPDATE_JOB_PARAGRAPHS = 'UPDATE_JOB_PARAGRAPHS'
export const JOB_PARAGRAPHS_UPDATED = 'JOB_PARAGRAPHS_UPDATED'
export const updateJobParagraphs = makeActionCreator(
  UPDATE_JOB_PARAGRAPHS,
  'params'
)
export const jobParagraphsUpdated = makeActionCreator(
  JOB_PARAGRAPHS_UPDATED,
  'job',
  'error'
)

export const UPDATE_JOBS_LIST = 'UPDATE_JOBS_LIST'
export const updateJobsList = makeActionCreator(UPDATE_JOBS_LIST, 'job')
