import {
  COGNITO_USER_FETCHED,
  CLEAN_COGNITO_USER,
  FETCH_CURRENT_USER,
  CURRENT_USER_FETCHED,
  CLEAN_CURRENT_USER,
  UPDATE_EMAIL_NOTIFICATIONS,
  EMAIL_NOTIFICATIONS_UPDATED
} from '../actions/user'

import { createReducer } from '../utils'

const initialState = {
  cognitoUser: {},
  currentUser: {},
  accountUsers: [],
  teamMemberError: null,
  emailNotificationsSaving: false
}

const handlers = {
  [COGNITO_USER_FETCHED]: (state, action) => ({
    ...state,
    cognitoUser: !action.error ? action.user : {}
  }),
  [CLEAN_COGNITO_USER]: (state, action) => ({
    ...state,
    cognitoUser: {}
  }),
  [FETCH_CURRENT_USER]: (state, action) => ({
    ...state,
    currentUser: {}
  }),
  [CURRENT_USER_FETCHED]: (state, action) => ({
    ...state,
    currentUser: !action.error ? action.user : {}
  }),
  [CLEAN_CURRENT_USER]: (state, action) => ({
    ...state,
    currentUser: {}
  }),
  [UPDATE_EMAIL_NOTIFICATIONS]: (state, action) => ({
    ...state,
    emailNotificationsSaving: true
  }),
  [EMAIL_NOTIFICATIONS_UPDATED]: (state, action) => ({
    ...state,
    currentUser: !action.error ? action.user : state.currentUser,
    emailNotificationsSaving: false
  })
}

export default createReducer(handlers, initialState)
