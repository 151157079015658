import { Auth, Cache } from 'aws-amplify'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { accountFetched, cleanAccount } from '../actions/account'
import { FETCH_INITIAL_CONFIGURATION } from '../actions/init'
import { cleanJob, cleanJobs, jobsFetched } from '../actions/job'
import {
  currentUserFetched,
  cleanCognitoUser,
  cleanCurrentUser
} from '../actions/user'
import {
  cleanUserNotifications,
  userNotificationsFetched
} from '../actions/userNotification'
import { loading } from '../actions/util'

import { getAccount } from '../api/account'
import { getJobs } from '../api/job'
import { getCognitoUser, getCurrentUser } from '../api/user'
import { getUserNotifications } from '../api/userNotification'
import history from '../history'

export function* fetchInitialConfiguration(action) {
  yield put(loading(true))

  const resultCognitoUser = yield call(getCognitoUser)
  const cognitoUserError = resultCognitoUser.error

  const state = yield select()
  const identityId = yield call(getCurrentUserIdentityId)
  const { cognitoUser } = state.rootReducer.userReducer
  if (!cognitoUser?.attributes || cognitoUserError) {
    yield put(loading(false))
    yield call(forceSignOut)
    yield call(cleanup)
    history.push('/app')
  } else {
    const accountId = cognitoUser.attributes['custom:accountId']
    const userId = cognitoUser.attributes.sub

    const { account, error } = yield call(getAccount, { id: accountId })
    yield put(accountFetched(account, error))

    if (error) {
      yield put(loading(false))
      yield call(forceSignOut)
      yield call(cleanup)
      history.push('/')
    } else {
      const resultUser = yield call(getCurrentUser, {
        accountId,
        identityId,
        id: userId
      })
      const user = resultUser.user
      yield put(currentUserFetched(user, resultUser.error))

      yield put(loading(false))

      const resultJobs = yield call(getJobs, { accountId })
      yield put(jobsFetched(resultJobs.jobs, resultJobs.error))

      const resultUserNotifications = yield call(getUserNotifications, {
        userId: user.id
      })
      yield put(
        userNotificationsFetched(
          resultUserNotifications.notifications,
          resultUserNotifications.error
        )
      )
    }
  }
}

export function* watchGetInitialConfiguration() {
  yield takeLatest(FETCH_INITIAL_CONFIGURATION, fetchInitialConfiguration)
}

export function* cleanup() {
  yield put(cleanAccount())
  yield put(cleanJob())
  yield put(cleanJobs())
  yield put(cleanCognitoUser())
  yield put(cleanCurrentUser())
  yield put(cleanUserNotifications())
}

export const forceSignOut = async () => {
  Cache.clear()
  await Auth.signOut()
}

const getCurrentUserIdentityId = async () => {
  const credentials = await Auth.currentCredentials()
  return credentials.identityId
}
