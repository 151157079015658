import { call, put, takeLatest } from 'redux-saga/effects'

import {
  UPDATE_USER_NOTIFICATION_READ,
  userNotificationsReadUpdated
} from '../actions/userNotification'
import { updateUserNotificationRead } from '../api/userNotification'

export function* sendUpdateUserNotificationRead(action) {
  const { notification, error } = yield call(
    updateUserNotificationRead,
    action.params
  )
  yield put(userNotificationsReadUpdated(notification, error))
}

export function* watchUpdateUserNotificationRead() {
  yield takeLatest(
    UPDATE_USER_NOTIFICATION_READ,
    sendUpdateUserNotificationRead
  )
}
