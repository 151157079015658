import {
  CREATE_STRIPE_CHECKOUT_SESSION,
  STRIPE_CHECKOUT_SESSION_CREATED,
  UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT,
  ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT_UPDATED
} from '../actions/stripe'

import { createReducer } from '../utils'

const initialState = {
  url: null,
  isUpdating: false
}

const handlers = {
  [CREATE_STRIPE_CHECKOUT_SESSION]: (state, action) => ({
    ...state,
    url: null
  }),
  [STRIPE_CHECKOUT_SESSION_CREATED]: (state, action) => ({
    ...state,
    url: !action.error ? action.url : null
  }),
  [UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT]: (state, action) => ({
    ...state,
    isUpdating: true
  }),
  [ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT_UPDATED]: (state, action) => ({
    ...state,
    card: !action.error ? action.card : {},
    isUpdating: false
  })
}

export default createReducer(handlers, initialState)
