import { API, I18n, graphqlOperation } from 'aws-amplify'

export const createStripeCheckoutSession = async params => {
  const mutation = `
    mutation createStripeCheckoutSession(
      $id: ID!
      $cognitoId: ID
      $amount: Int
    ) {
      createStripeCheckoutSession(
        id: $id
        cognitoId: $cognitoId
        amount: $amount
      ) {
        url
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { url: response.data.createStripeCheckoutSession.url }
  } catch (err) {
    return { error: I18n.get('Failed to get setup card.') }
  }
}

export const updateAccountBalanceWithStripeCheckout = async params => {
  const mutation = `
    mutation updateAccountBalanceWithStripeCheckout(
      $id: ID!
      $cognitoId: ID
      $stripeCheckoutSessionId: String!
    ) {
      updateAccountBalanceWithStripeCheckout(
        id: $id
        cognitoId: $cognitoId
        stripeCheckoutSessionId: $stripeCheckoutSessionId
      ) {
        id
        status
        type
        freeSecondsLeft
        paidSecondsLeft
        preferredTranscriptionLanguage
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { account: response.data.updateAccountBalanceWithStripeCheckout }
  } catch (err) {
    return { error: I18n.get('Failed to update card.') }
  }
}
