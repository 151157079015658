import { all, fork } from 'redux-saga/effects'

import {
  watchGetAccount,
  watchUpdateAccountPreferredLanguage,
  watchDeleteAccount
} from './account'
import { watchGetInitialConfiguration } from './init'
import {
  watchGetJobs,
  watchGetJob,
  watchResetJobTranscript,
  watchStartTranslation,
  watchUpdateJobName,
  watchUpdateJobParagraphs
} from './job'
import {
  watchCreateStripeCheckoutSession,
  watchUpdateAccountBalanceWithStripeCheckout
} from './stripe'
import { watchGetCognitoUser, watchUpdateEmailNotifications } from './user'
import { watchUpdateUserNotificationRead } from './userNotification'

export default function* rootSaga() {
  yield all([
    fork(watchGetAccount),
    fork(watchUpdateAccountPreferredLanguage),
    fork(watchDeleteAccount),
    fork(watchGetInitialConfiguration),
    fork(watchGetJob),
    fork(watchGetJobs),
    fork(watchResetJobTranscript),
    fork(watchStartTranslation),
    fork(watchUpdateJobName),
    fork(watchUpdateJobParagraphs),
    fork(watchCreateStripeCheckoutSession),
    fork(watchUpdateAccountBalanceWithStripeCheckout),
    fork(watchGetCognitoUser),
    fork(watchUpdateEmailNotifications),
    fork(watchUpdateUserNotificationRead)
  ])
}
