import {
  LOADING,
  ERROR_MESSAGE,
  INSERT_TRANSCRIBE_STATUS_UPDATES,
  REMOVE_TRANSCRIBE_STATUS_UPDATE,
  INSERT_TRANSLATE_STATUS_UPDATES,
  REMOVE_TRANSLATE_STATUS_UPDATE
} from '../actions/util'

import { TRANSLATE_LANGUAGES, createReducer } from '../utils'

const initialState = {
  loading: false,
  errorMessage: null,
  updates: [],
  translationUpdates: []
}

const handlers = {
  [LOADING]: (state, action) => ({ ...state, loading: action.value }),
  [ERROR_MESSAGE]: (state, action) => ({ ...state, errorMessage: action.msg }),
  [INSERT_TRANSCRIBE_STATUS_UPDATES]: (state, action) => {
    const { accountId, creationTime, jobName, languageCode, name, status } =
      action.object
    const id = jobName
    const title = `${name} (${languageCode})`
    const description = `Your job status is ${status}`
    const isClickable = status === 'COMPLETED'
    const object = {
      accountId,
      creationTime,
      id,
      title,
      description,
      isClickable
    }

    let updates = [...state.updates]
    const index = updates.findIndex(item => item.id === id)
    index > -1 ? (updates[index] = object) : updates.push(object)
    return { ...state, updates }
  },
  [REMOVE_TRANSCRIBE_STATUS_UPDATE]: (state, action) => {
    const index = state.updates.findIndex(({ id }) => id === action.id)
    if (index > -1) {
      state.updates.splice(index, 1)
    }
    return {
      ...state,
      updates: [...state.updates]
    }
  },
  [INSERT_TRANSLATE_STATUS_UPDATES]: (state, action) => {
    const { accountId, creationTime, jobName, name, status } =
      action.object.input

    const targetLang = action.object.targetLang
    const language = TRANSLATE_LANGUAGES.find(
      ({ value }) => value === targetLang
    )?.label

    const id = jobName
    const title = `${name} (${targetLang})`
    const description = `Your translation in ${language} is completed.`
    const isClickable = status === 'COMPLETED'
    const object = {
      accountId,
      creationTime,
      id,
      title,
      description,
      isClickable
    }

    let translationUpdates = [...state.translationUpdates]
    const index = translationUpdates.findIndex(item => item.id === id)
    index > -1
      ? (translationUpdates[index] = object)
      : translationUpdates.push(object)

    return { ...state, translationUpdates }
  },
  [REMOVE_TRANSLATE_STATUS_UPDATE]: (state, action) => {
    const index = state.translationUpdates.findIndex(
      ({ id }) => id === action.id
    )
    if (index > -1) {
      state.translationUpdates.splice(index, 1)
    }
    return {
      ...state,
      translationUpdates: [...state.translationUpdates]
    }
  }
}

export default createReducer(handlers, initialState)
