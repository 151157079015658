import { I18n } from 'aws-amplify'

import history from '../../history'

import NinjaMoonSvg from '../Svg/NinjaMoon'

const Hero = () => (
  <section className='my-2 px-2 py-3 bg-light'>
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12 col-lg-7 my-5 text-center text-lg-left'>
          <h1 className='hero-display'>
            <strong>
              {I18n.get('Create AI-generated transcripts in minutes.')}
            </strong>
          </h1>
          <p className='landing-page-copy'>
            {I18n.get(
              'Transcribe Ninja uses the latest AI technology on speech recognition.'
            )}
          </p>
          <button
            className='btn btn-primary landing-page-btn'
            type='button'
            onClick={() => history.push('/app')}
          >
            <strong>{I18n.get('Get started')}</strong>
          </button>
          <small className='mt-2 d-block'>
            <strong>No credit card required.</strong>
          </small>
        </div>
        <div className='d-none d-lg-block col-sm-12 col-lg-5 my-5'>
          {/* <img
            src={ninjaMoonSvg}
            className='img-fluid'
            alt={'Transcribe Ninja Moon'}
          /> */}
          <NinjaMoonSvg className='img-fluid' />
        </div>
        <div className='d-block d-lg-none col-sm-12 col-lg-5 my-5 text-center'>
          {/* <img
            src={ninjaMoonSvg}
            className='img-fluid w-60'
            alt={'Transcribe Ninja Moon'}
          /> */}
          <NinjaMoonSvg className='img-fluid w-60' />
        </div>
      </div>
    </div>
  </section>
)

export default Hero
