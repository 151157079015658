import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_JOBS,
  jobsFetched,
  FETCH_JOB,
  jobFetched,
  RESET_JOB_TRANSCRIPT,
  jobTranscriptReset,
  START_TRANSLATION,
  translationStarted,
  UPDATE_JOB_NAME,
  jobNameUpdated,
  UPDATE_JOB_PARAGRAPHS,
  jobParagraphsUpdated
} from '../actions/job'
import { loading, errorMessage } from '../actions/util'

import {
  getJobs,
  getJob,
  resetJobTranscript,
  startTranslation,
  updateJobName,
  updateJobParagraphs
} from '../api/job'

import { getTranslationText, sortTraslationsByName } from '../utils'

export function* fetchJobs(action) {
  yield put(loading(true))
  const { jobs, error } = yield call(getJobs, action.params)
  yield put(jobsFetched(jobs, error))
  yield put(loading(false))
}

export function* watchGetJobs() {
  yield takeLatest(FETCH_JOBS, fetchJobs)
}

export function* fetchJob(action) {
  yield put(loading(true))
  const { job, error } = yield call(getJob, action.params)

  let promises = []
  if (job.translations) {
    for (let i = 0; i < job.translations.length; i++) {
      const { key, url } = job.translations[i]
      promises.push(getTranslationText(key, url))
    }
  }

  const translations = yield all(promises)
  const sortedTranslations = sortTraslationsByName([...translations])
  job['translations'] = sortedTranslations

  yield put(jobFetched(job, error))
  yield put(loading(false))
}

export function* watchGetJob() {
  yield takeLatest(FETCH_JOB, fetchJob)
}

export function* sendResetJobTranscript(action) {
  yield put(loading(true))
  const { job, error } = yield call(resetJobTranscript, action.params)
  yield put(jobTranscriptReset(job, error))
  yield put(loading(false))
}

export function* watchResetJobTranscript() {
  yield takeLatest(RESET_JOB_TRANSCRIPT, sendResetJobTranscript)
}

export function* sendStartTranslation(action) {
  const { job, error } = yield call(startTranslation, action.params)
  yield put(translationStarted(job, error))
  if (error) {
    yield put(errorMessage(error))
  }
}

export function* watchStartTranslation() {
  yield takeLatest(START_TRANSLATION, sendStartTranslation)
}

export function* sendUpdateJobName(action) {
  const { job, error } = yield call(updateJobName, action.params)
  yield put(jobNameUpdated(job, error))
}

export function* watchUpdateJobName() {
  yield takeLatest(UPDATE_JOB_NAME, sendUpdateJobName)
}

export function* sendUpdateJobParagraphs(action) {
  const { job, error } = yield call(updateJobParagraphs, action.params)
  yield put(jobParagraphsUpdated(job, error))
}

export function* watchUpdateJobParagraphs() {
  yield takeLatest(UPDATE_JOB_PARAGRAPHS, sendUpdateJobParagraphs)
}
