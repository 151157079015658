import { Fragment, Suspense, lazy, memo } from 'react'

import { Benefits, Hero, Footer } from '../components/LandingPage'

const Pricing = lazy(() =>
  import(/* webpackChunkName: "Pricing" */ '../components/LandingPage/Pricing')
)

export default memo(() => {
  return (
    <Fragment>
      <Hero />
      <Benefits />
      <Suspense fallback={<span />}>
        <Pricing />
      </Suspense>
      <Suspense fallback={<span />}>
        <Footer />
      </Suspense>
    </Fragment>
  )
})
