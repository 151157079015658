import { lazy } from 'react'

import LandingPage from './views/LandingPage'
import Root from './views/Root'

const App = lazy(() => import(/* webpackChunkName: "App" */ './views/App'))
const Upload = lazy(() =>
  import(/* webpackChunkName: "Upload" */ './views/Upload')
)
const Editor = lazy(() =>
  import(/* webpackChunkName: "Editor" */ './views/Jobs/Editor')
)
const JobsRoot = lazy(() =>
  import(/* webpackChunkName: "JobsRoot" */ './views/Jobs/Root')
)
const JobsList = lazy(() =>
  import(/* webpackChunkName: "JobsList" */ './views/Jobs/List')
)
const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ './views/Profile')
)
const Settings = lazy(() =>
  import(/* webpackChunkName: "Settings" */ './views/Settings')
)

// No auth
const Privacy = lazy(() =>
  import(/* webpackChunkName: "Privacy" */ './views/Privacy')
)
const SupportedLanguages = lazy(() =>
  import(
    /* webpackChunkName: "SupportedLanguages" */ './views/SupportedLanguages'
  )
)
const Terms = lazy(() =>
  import(/* webpackChunkName: "Terms" */ './views/Terms')
)

const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        component: LandingPage,
        exact: true
      },
      {
        path: '/app',
        component: App,
        routes: [
          {
            path: '/app',
            component: Upload,
            exact: true
          },
          {
            path: '/app/jobs',
            component: JobsRoot,
            routes: [
              {
                path: '/app/jobs',
                component: JobsList,
                exact: true
              },
              {
                path: '/app/jobs/:jobId',
                component: Editor,
                exact: true
              }
            ]
          },
          {
            path: '/app/profile',
            component: Profile,
            exact: true
          },
          {
            path: '/app/settings',
            component: Settings,
            exact: true
          }
        ]
      },
      {
        path: '/supported-languages',
        component: SupportedLanguages
      },
      {
        path: '/privacy-policy',
        component: Privacy
      },
      {
        path: '/terms-of-service',
        component: Terms
      }
    ]
  }
]

export default routes
