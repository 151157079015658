import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='171.6012mm'
      height='175.38094mm'
      viewBox='0 0 171.6012 175.38094'
      {...props}
    >
      <defs>
        <linearGradient id='c'>
          <stop offset={0} stopColor='#fdfefe' stopOpacity={1} />
          <stop offset={1} stopColor='#fdfefe' stopOpacity={0} />
        </linearGradient>
        <linearGradient id='b'>
          <stop offset={0} stopColor='#fff' stopOpacity={1} />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <marker refX={0} refY={0} orient='auto' overflow='visible'>
          <path
            d='M-10 0l-4 4L0 0l-14-4z'
            fillRule='evenodd'
            stroke='#000'
            strokeWidth='.8pt'
          />
        </marker>
        <linearGradient
          xlinkHref='#a'
          id='f'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-.498 .938)'
        />
        <linearGradient id='a'>
          <stop offset={0} stopColor='#223469' stopOpacity={1} />
          <stop offset={1} stopColor='#223469' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref='#a'
          id='l'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(1.31148 0 0 1.37823 107.22 -18.823)'
        />
        <linearGradient
          xlinkHref='#a'
          id='h'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(.70965 0 0 .68331 -6.457 -118.979)'
        />
        <linearGradient
          xlinkHref='#a'
          id='n'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(1.5006 0 0 1.5006 77.477 -43.531)'
        />
        <linearGradient
          xlinkHref='#a'
          id='j'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(.59952 0 0 .57727 -102.06 -115.493)'
        />
        <linearGradient
          xlinkHref='#a'
          id='p'
          x1={-172.90376}
          y1={-62.177105}
          x2={-138.92661}
          y2={-62.177105}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(.57347 0 0 .59142 -87.613 -50.58)'
        />
        <linearGradient
          xlinkHref='#b'
          x1={-297.53455}
          y1={66.665321}
          x2={-222.17613}
          y2={66.665321}
          gradientUnits='userSpaceOnUse'
        />
        <linearGradient
          xlinkHref='#b'
          gradientUnits='userSpaceOnUse'
          x1={-297.53455}
          y1={66.665321}
          x2={-222.17613}
          y2={66.665321}
          gradientTransform='matrix(.76426 0 0 1 -70.14 0)'
        />
        <clipPath clipPathUnits='userSpaceOnUse'>
          <ellipse
            cx={116.22768}
            cy={192.2009}
            rx={70.870537}
            ry={73.13839}
            opacity={1}
            fill='#131313'
            fillOpacity={1}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <linearGradient
          xlinkHref='#b'
          x1={-0.37797621}
          y1={206.75298}
          x2={27.214285}
          y2={206.75298}
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(7.13834 0 0 .63636 -51.268 -9.002)'
        />
        <linearGradient
          xlinkHref='#b'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(.76426 0 0 1 -70.14 0)'
          x1={-297.53455}
          y1={66.665321}
          x2={-222.17613}
          y2={66.665321}
        />
        <clipPath clipPathUnits='userSpaceOnUse'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='s'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='o'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='m'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='k'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='i'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='g'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='e'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='matrix(0 -1 -1 0 0 0)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='d'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='rotate(-90)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <linearGradient
          xlinkHref='#b'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(2.16333 0 0 1.39356 338.347 -107.108)'
          x1={-297.53455}
          y1={66.665321}
          x2={-222.17613}
          y2={66.665321}
        />
        <clipPath clipPathUnits='userSpaceOnUse'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <linearGradient
          xlinkHref='#b'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(2.16333 0 0 1.39356 356.266 -104.39)'
          x1={-297.53455}
          y1={66.665321}
          x2={-222.17613}
          y2={66.665321}
        />
        <clipPath clipPathUnits='userSpaceOnUse' id='q'>
          <circle
            cx={113.89475}
            cy={145.19113}
            r={85.470978}
            transform='rotate(-90)'
            opacity={1}
            fill='#080808'
            fillOpacity={0.235772}
            stroke='#fff'
            strokeWidth={0}
            strokeLinejoin='round'
            strokeMiterlimit={4}
            strokeDasharray='none'
            strokeOpacity={1}
            paintOrder='stroke markers fill'
          />
        </clipPath>
        <linearGradient
          xlinkHref='#c'
          id='r'
          x1={63.784943}
          y1={-114.3756}
          x2={227.40793}
          y2={-114.3756}
          gradientUnits='userSpaceOnUse'
        />
      </defs>
      <g
        transform='translate(-26.836 -59.92)'
        display='inline'
        stroke='none'
        strokeLinecap='round'
        strokeLinejoin='bevel'
        strokeMiterlimit={4}
        strokeDasharray='none'
        paintOrder='stroke markers fill'
      >
        <ellipse
          cx={145.59644}
          cy={-114.3756}
          rx={81.811493}
          ry={81.811478}
          transform='rotate(90)'
          clipPath='url(#d)'
          display='inline'
          opacity={1}
          fill='#213369'
          fillOpacity={0.392157}
          strokeWidth={0.916789}
        />
        <ellipse
          cx={-156.41347}
          cy={-61.238811}
          rx={16.988577}
          ry={16.988573}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#e)'
          display='inline'
          opacity={0.121406}
          fill='url(#f)'
          fillOpacity={1}
          strokeWidth={0.190376}
        />
        <ellipse
          cx={-117.10161}
          cy={-161.46504}
          rx={12.055892}
          ry={11.608481}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#g)'
          display='inline'
          opacity={0.121406}
          fill='url(#h)'
          fillOpacity={1}
          strokeWidth={0.13257}
        />
        <ellipse
          cx={-195.534}
          cy={-151.38609}
          rx={10.185005}
          ry={9.8070259}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#i)'
          display='inline'
          opacity={0.121406}
          fill='url(#j)'
          fillOpacity={1}
          strokeWidth={0.111997}
        />
        <ellipse
          style={{
            mixBlendMode: 'multiply'
          }}
          cx={-97.260193}
          cy={-104.51709}
          rx={22.280243}
          ry={23.414169}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#k)'
          display='inline'
          opacity={0.13738}
          fill='url(#l)'
          fillOpacity={1}
          strokeWidth={0.255951}
        />
        <ellipse
          style={{
            mixBlendMode: 'multiply'
          }}
          cx={-156.48909}
          cy={-136.83406}
          rx={25.493044}
          ry={25.493038}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#m)'
          display='inline'
          opacity={0.121406}
          fill='url(#n)'
          fillOpacity={1}
          strokeWidth={0.285678}
        />
        <ellipse
          style={{
            mixBlendMode: 'multiply'
          }}
          cx={-177.02625}
          cy={-87.352501}
          rx={9.7424707}
          ry={10.047434}
          transform='matrix(0 -1 -1 0 0 0)'
          clipPath='url(#o)'
          display='inline'
          opacity={0.121406}
          fill='url(#p)'
          fillOpacity={1}
          strokeWidth={0.110871}
        />
        <ellipse
          cx={145.59644}
          cy={-114.3756}
          rx={81.811493}
          ry={81.811478}
          transform='rotate(-75.4 298.18 35.816)'
          clipPath='url(#q)'
          display='inline'
          opacity={0.993485}
          fill='url(#r)'
          fillOpacity={1}
          strokeWidth={0.916789}
        />
      </g>
      <g
        clipPath='url(#s)'
        fillOpacity={1}
        transform='translate(-26.836 -59.92)'
        display='inline'
      >
        <path
          d='M44.585 241.221l-.036-6.056 33.759-21.724-.03 4.586z'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='#fff'
          strokeWidth='.264583px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
        <path
          d='M.495 180.55h131.477v116.985H.495z'
          display='inline'
          opacity={1}
          fill='#3f47ec'
          stroke='none'
          strokeWidth={0.543327}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          paintOrder='stroke markers fill'
        />
        <path
          style={{
            mixBlendMode: 'screen'
          }}
          d='M87.945 192.89H121.6v43.483H87.945z'
          display='inline'
          opacity={0.488818}
          fill='#d2d5d1'
          stroke='none'
          strokeWidth={0.548828}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <path
          style={{
            mixBlendMode: 'multiply'
          }}
          d='M48.595 192.797H82.25v43.482H48.595z'
          display='inline'
          opacity={0.495208}
          fill='#0c1179'
          stroke='none'
          strokeWidth={0.548828}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <path
          style={{
            mixBlendMode: 'screen'
          }}
          transform='scale(1 -1)'
          d='M88.446-287.44h33.656v43.482H88.446zM48.97-287.44h33.655v43.482H48.97z'
          display='inline'
          opacity={0.488818}
          fill='#d2d5d1'
          stroke='none'
          strokeWidth={0.548828}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <path
          style={{
            mixBlendMode: 'multiply'
          }}
          transform='scale(1 -1)'
          d='M7.907-287.44h33.655v43.482H7.907z'
          display='inline'
          opacity={0.523962}
          fill='#d2d5d1'
          stroke='none'
          strokeWidth={0.548828}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <path
          style={{
            mixBlendMode: 'screen'
          }}
          transform='scale(1 -1)'
          d='M8.24-236.716h33.655v43.483H8.24z'
          display='inline'
          opacity={0.488818}
          fill='#d2d5d1'
          stroke='none'
          strokeWidth={0.548828}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <path
          d='M87.96 225.635l.058-11.447 33.476-20.874.106 10.944zM87.908 233.528l-.037-4.45 33.76-21.724.064 3.925z'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='none'
          strokeWidth='.264583px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
        <path
          d='M48.903 276.5l-.037-6.056 33.76-21.724-.03 5.153z'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='#fff'
          strokeWidth='.264583px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
        <path
          d='M49.083 286.805l-.036-6.056 33.506-22.764-.124 5.626z'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='#fff'
          strokeWidth='.263219px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
        <path
          d='M8.24 236.716l.016-3.063 33.759-21.724-.03 5.153-16.913 11.464-12.134 8.225-4.698-.055'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='none'
          strokeWidth='.264583px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
        <path
          d='M38.607 236.717l-19.146-.009 22.481-15.514-.124 13.577z'
          display='inline'
          opacity={0.322684}
          fill='#fff'
          stroke='none'
          strokeWidth='.263219px'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity={1}
        />
      </g>
      <g transform='translate(-26.836 -59.92)' display='inline'>
        <g strokeOpacity={1}>
          <path
            d='M563.343-86.589l88.084 58.118-2.478 3.28-89.63-60.081z'
            fill='none'
            stroke='#fff'
            strokeWidth='.264583px'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            transform='translate(-192.154 168.792) scale(.50233)'
          />
          <path
            d='M642.513-28.97c-5.25-3.562-6.013-4.007-5.978-4.055.022-.03.232-.292.465-.582.513-.637 1.168-1.666 1.456-2.145.193-.32.493-.773.62-1.086.029-.068.073-.125.1-.125.064 0 .731.422 2.812 1.776 1.696 1.105 8.525 5.603 9.146 5.991l1.522.951c-.475.94-.774 1.623-1.023 1.993-.545.771-1.314 1.958-1.723 2.18-.004 0-2.785-1.768-6.074-4z'
            opacity={1}
            fill='#000'
            fillOpacity={0.988235}
            stroke='#000'
            strokeWidth={0.723}
            strokeLinecap='round'
            strokeLinejoin='bevel'
            strokeMiterlimit={4}
            strokeDasharray='none'
            paintOrder='stroke markers fill'
            transform='translate(-192.154 168.792) scale(.50233)'
          />
        </g>
        <path
          d='M516.364 67.922c5.33-7.434 7.588-16.458 9.74-23.798 1.295-7.064 2.689-21.53 2.79-25.536.19-3.968-1.575-20.175-3.504-22.36-2.342-3.653-4.277-.113-6.233-4.476-1.005-3.018 7.171-12.09 12.837-14.975 7.466-2.93 13.036-3.56 20.156-3.576 5.448-.125 11.14 2.419 15.13 5.228 5.02 3.595 7.976 7.983 9.078 13.48.331 1.648.33 5.56 0 7.612-1.048 6.499-3.563 11.171-7.331 13.62-2.368 1.538-5.737 3.067-8.198 3.719-.66.174-1.128.368-1.128.467 0 .233.16.215 1.567-.17 2.238-.614 5.869-2.232 7.493-3.34l.495-.337.298 1.24c.633 2.63.713 4.457.713 4.457s-1.27.62-1.836.86c-4.259 1.801-6.849 2.605-9.665 2.998-2.049.285-6.426.4-8.465.222-.943-.083-1.774-.114-1.846-.07.797.693 1.707.467 3.098.524 2.467.1 6.422-.094 8.147-.4 2.55-.454 4.86-1.19 8.27-2.636 2.05-.753 4.18-1.762 6.064-2.518 4.23-1.683 5.996-5.075 7.23-6.109 3.442-2.717 5.47-1.954 8.82.919.766.349.223.986-1.186 1.392-1.544.445-3.396 1.542-4.51 2.672-.372.377-.845 1.09-1.184 1.782-.991 2.027-3.837 5.921-5.533 7.572a15.16 15.16 0 01-1.757 1.429c-.975.654-3.566 1.975-3.873 1.975-.238 0-.238.003.212 2.04 1.62 7.177 2.115 13.991 1.698 18.04-.308 6.094-5.893 15.704-10.459 18.162-.71-4.98-1.596-13.013-8.145-13.373-21.482-.335-23.962 11.907-38.983 13.264zm41.505-38.346c7.582-2.748 12.403-4.973 18.711-8.638.868-.504 1.732-.975 1.919-1.046.282-.108 6.359-4.173 6.222-4.337-.224-.27-6.346 3.753-7.006 4.196-1.055.707-5.503 3.18-7.662 4.26-3.408 1.705-7.442 3.394-12.357 5.176-1.36.493-2.493.916-2.519.94-.058.056.105.33.195.33.036 0 1.16-.396 2.497-.881zm-6.824-24.514c5.159-.829 7.877-.967 11.61-.589 2.495.253 4.919.279 5.732.062 2.293-.612 4.04-3.227 4.065-6.083.013-1.492-.286-2.28-1.258-3.319-1.376-1.47-3.836-2.571-7.098-3.176-2.867-.53-13.472-.482-16.89.078-3.806.624-6.382 2.02-7.34 3.98-.79 1.612-.697 3.732.24 5.545.327.631 1.342 1.755 2.115 2.34 2.16 1.636 4.2 1.905 8.824 1.162z'
          opacity={1}
          fill='#212529'
          fillOpacity={1}
          stroke='none'
          strokeWidth={0.26264}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeOpacity={0.996306}
          paintOrder='stroke markers fill'
          transform='translate(-161.983 146.409) scale(.50233)'
        />
        <path
          d='M126.763 151.282l-.784-.427.408-.73c.177-.315.3-.67.46-1.072.058 0 1.652 1.094 1.798 1.188-.36.766-1.121 1.68-1.121 1.68s-.355-.366-.761-.64zM96.263 130.834c-3.913-2.628-7.3-4.867-7.31-4.895-.01-.029.54-.106 1.008-.254l.85-.269.638.415c2.424 1.576 13.692 8.93 13.692 8.93-.2.111-.62.309-1.577.995-.036-.002-3.387-2.295-7.3-4.922z'
          opacity={1}
          fill='#fff'
          fillOpacity={1}
          stroke='#fff'
          strokeWidth={0.101015}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={0.996306}
          paintOrder='stroke markers fill'
        />
        <path
          d='M111.48 148.834c-1.08-.383-2.129-1.627-2.297-2.622-.262-1.549.383-2.718 2.547-3.387.863-.267 2.032-.353 5.446-.446 2.483-.067 4.805.15 6.002.563 2.238.717 2.996 2.588 1.69 4.542-.766 1.148-1.395 1.616-4.713 1.122-2.15-.32-3.559-.015-5.122.208-2.548.363-2.583.363-3.553.02z'
          opacity={1}
          fill='#fff'
          fillOpacity={0.992157}
          stroke='#fff'
          strokeWidth={0.4}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={0.996306}
          paintOrder='stroke markers fill'
        />
        <ellipse
          cx={114.70353}
          cy={145.63229}
          rx={0.68325603}
          ry={0.6955083}
          fill='#000'
          fillOpacity={0.988235}
          stroke='#000001'
          strokeWidth={0.261212}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <ellipse
          cx={122.77618}
          cy={145.56862}
          rx={0.68325603}
          ry={0.6955083}
          fill='#000'
          fillOpacity={0.988235}
          stroke='#000001'
          strokeWidth={0.261212}
          strokeLinecap='round'
          strokeLinejoin='bevel'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
        <ellipse
          cx={114.50316}
          cy={145.62714}
          rx={0.33072916}
          ry={0.2716704}
          opacity={1}
          fill='#000'
          fillOpacity={1}
          stroke='#fff'
          strokeWidth={0}
          strokeLinejoin='round'
          strokeMiterlimit={4}
          strokeDasharray='none'
          strokeOpacity={1}
          paintOrder='stroke markers fill'
        />
      </g>
    </svg>
  )
}

export default SvgComponent
