import { makeActionCreator } from '../utils'

export const CLEAN_ACCOUNT = 'CLEAN_ACCOUNT'
export const cleanAccount = makeActionCreator(CLEAN_ACCOUNT)

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const deleteAccount = makeActionCreator(DELETE_ACCOUNT, 'params')
export const accountDelete = makeActionCreator(
  ACCOUNT_DELETED,
  'account',
  'error'
)

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const ACCOUNT_FETCHED = 'ACCOUNT_FETCHED'
export const fetchAccount = makeActionCreator(FETCH_ACCOUNT, 'params')
export const accountFetched = makeActionCreator(
  ACCOUNT_FETCHED,
  'account',
  'error'
)

export const UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE =
  'UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE'
export const ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE_UPDATED =
  'ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE_UPDATED'
export const updateAccountPreferredTranscriptionLanguage = makeActionCreator(
  UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE,
  'params'
)
export const accountPreferredTranscriptionLanguageUpdated = makeActionCreator(
  ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE_UPDATED,
  'account',
  'error'
)
