import { Auth, Hub } from 'aws-amplify'
import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components'
import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10)

export class AuthService {
  cognitoUser = null

  async changePassword(oldPassword, newPassword) {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser()
      return await Auth.changePassword(
        this.cognitoUser,
        oldPassword,
        newPassword
      )
    } catch (err) {
      throw err
    }
  }

  async confirmSignUp(email, code) {
    try {
      await Auth.confirmSignUp(email, code, {
        clientMetadata: {
          confirmSignUp: 'yes'
        }
      })
    } catch (err) {
      throw err
    }
  }

  async currentUser() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser()
    } catch {
      this.cognitoUser = null
    }
  }

  async forgotPassword(email) {
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(email)
      return CodeDeliveryDetails
    } catch (err) {
      throw err
    }
  }

  async forgotPasswordSubmit(email, code, newPassword) {
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, newPassword)
      return data
    } catch (err) {
      throw err
    }
  }

  async isAuthenticated() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser()
      return true
    } catch {
      this.cognitoUser = null
      return false
    }
  }

  async resendSignUp(email) {
    try {
      await Auth.resendSignUp(email)
    } catch (err) {
      throw err
    }
  }

  async signUp(email, password) {
    try {
      const params = {
        username: email,
        password,
        attributes: {
          email,
          'custom:accountId': nanoid()
        }
      }
      await Auth.signUp(params)
    } catch (err) {
      throw err
    }
  }

  async signIn(email, password) {
    try {
      this.cognitoUser = await Auth.signIn(email, password)
    } catch (err) {
      throw err
    }
  }

  async signOut() {
    await Auth.signOut()
    this.cognitoUser = null
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignedOut
    })
  }

  async updateUserEmail(email) {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser()
      return await Auth.updateUserAttributes(this.cognitoUser, { email })
    } catch (err) {
      throw err
    }
  }

  async verifyUserAttribute(attribute, verificationCode) {
    try {
      return await Auth.verifyCurrentUserAttributeSubmit(
        attribute,
        verificationCode
      )
    } catch (err) {
      throw err
    }
  }
}
