import { API, Auth, I18n, graphqlOperation } from 'aws-amplify'

export const getCognitoUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    return { user }
  } catch (err) {
    return { error: I18n.get('Failed to get current user.') }
  }
}

export const getCurrentUser = async params => {
  const query = `
    query getCurrentUser(
    	$accountId: ID!
      $id: ID!
      $cognitoId: ID
      $identityId: String
    ) {
      getCurrentUser(
      	accountId: $accountId
        id: $id
        cognitoId: $cognitoId
        identityId: $identityId
      ) {
      	accountId
        id
        email
        status
        type
        enableEmails
      }
    }
  `

  try {
    const operation = graphqlOperation(query, params)
    const response = await API.graphql(operation)
    return { user: response.data.getCurrentUser }
  } catch (err) {
    return { error: I18n.get('Failed to get user.') }
  }
}

export const updateEmailNotifications = async params => {
  const mutation = `
    mutation updateEmailNotifications(
      $accountId: ID!
      $cognitoId: ID
      $enableEmails: Boolean
    ) {
      updateEmailNotifications(
        accountId: $accountId
        cognitoId: $cognitoId
        enableEmails: $enableEmails
      ) {
        accountId
        id
        email
        status
        type
        enableEmails
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { user: response.data.updateEmailNotifications }
  } catch (err) {
    return { error: I18n.get('Failed to update email notifications.') }
  }
}
