import history from '../../history'

// import logoSvg from '../../assets/images/logo.svg'
import LogoSvg from '../Svg/Logo'

export default () => (
  <a
    className='navbar-brand text-hide'
    onClick={() => history.push('/')}
    role='button'
  >
    {/* <img src={logoSvg} alt='Transcribe Ninja Logo' height='40' width='60' />{' '} */}
    <LogoSvg width='60' height='40' />
    Transcribe Ninja
  </a>
)
