import { I18n } from 'aws-amplify'

import history from '../../history'

const Pricing = () => (
  <section className='mt-4 mb-2 px-2 pt-3 pb-5 bg-light'>
    <div className='container'>
      <div className='row'>
        <div className='col text-center mb-3'>
          <h2 className='mb-0'>{I18n.get('Pricing')}</h2>
          <small>
            <a href='https://stripe.com/' target='blank' rel='noopener'>
              {I18n.get('(Powered by Stripe)')}
            </a>
          </small>
        </div>
      </div>
      <div className='card-deck flex-grow-1 mb-2'>
        <div className='card landing-price-card mx-2'>
          <div className='card-body d-flex flex-column'>
            <h4 className='card-title'>{I18n.get('Basic')}</h4>
            <h3 className='text-blue'>
              Free <small className='text-muted'></small>
            </h3>
            <p className='card-text'>First 15 minutes only</p>
            <small className='card-text mb-4'>
              <strong>{I18n.get('No credit card')}</strong>
            </small>
            <p className='card-text' style={{ marginBottom: '0.2em' }}>
              No extra minutes
            </p>
            <ul className='list-group list-group-flush'>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                Max file size is 100 MB
              </li>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                Max file duration is 5 minutes
              </li>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                One file at a time
              </li>
            </ul>
          </div>
        </div>
        <div className='card landing-price-card mx-2'>
          <div className='card-body d-flex flex-column'>
            <h4 className='card-title'>{I18n.get('Pay as you go')}</h4>
            <h3 className='text-blue'>$10 minimum payment</h3>
            <p className='card-text'>60 minutes</p>
            <small className='card-text mb-4'>
              <strong>{I18n.get('Charged by the second')}</strong>
            </small>
            <p className='card-text' style={{ marginBottom: '0.2em' }}>
              No extra minutes
            </p>
            <ul className='list-group list-group-flush'>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                Max file size is 2 GB
              </li>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                Max file duration is 4 hours
              </li>
              <li
                className='list-group-item price-group-item'
                style={{ paddingLeft: 0 }}
              >
                One file at a time
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center'>
          <button
            className='btn btn-primary landing-page-btn'
            type='button'
            onClick={() => history.push('/app')}
          >
            <strong>{I18n.get('Sign up for free')}</strong>
          </button>
        </div>
      </div>
    </div>
  </section>
)

export default Pricing
