import { API, I18n, graphqlOperation } from 'aws-amplify'

export const getUserNotifications = async params => {
  const query = `
    query getUserNotifications(
      $userId: ID!
      $cognitoId: ID
    ) {
      getUserNotifications(
        userId: $userId
        cognitoId: $cognitoId
      ) {
        userId
        accountId
        creationTime
        name
        read
      }
    }
  `

  try {
    const operation = graphqlOperation(query, params)
    const response = await API.graphql(operation)
    return { notifications: response.data.getUserNotifications }
  } catch (err) {
    return { error: I18n.get('Failed to get notifications.') }
  }
}

export const updateUserNotificationRead = async params => {
  const mutation = `
    mutation updateUserNotificationRead(
      $userId: ID!
      $creationTime: GraphQLTimestamp!
      $cognitoId: ID
    ) {
      updateUserNotificationRead(
        userId: $userId
        creationTime: $creationTime
        cognitoId: $cognitoId
      ) {
        userId
        accountId
        creationTime
        name
        read
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { notification: response.data.updateUserNotificationRead }
  } catch (err) {
    return { error: I18n.get('Failed to update notification.') }
  }
}
