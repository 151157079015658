import { I18n } from 'aws-amplify'
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/solid'

import history from '../../history'

function goToApp() {
  history.push('/app')
}

export default function LoginButton() {
  return (
    <li className='nav-item active'>
      <button
        className='btn btn-primary my-2 my-sm-0'
        type='submit'
        data-toggle='collapse'
        data-target='.navbar-collapse.show'
        onClick={goToApp}
      >
        <ArrowRightEndOnRectangleIcon
          className='mr-2'
          style={{ width: '1.2em' }}
        />
        <strong>{I18n.get('Go To App')}</strong>
      </button>
    </li>
  )
}
