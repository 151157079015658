import { API, I18n, graphqlOperation } from 'aws-amplify'

export const deleteAccount = async params => {
  const mutation = `
    mutation deleteAccount(
      $id: ID!
      $cognitoId: ID
    ) {
      deleteAccount(
        id: $id
        cognitoId: $cognitoId
      ) {
        id
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { account: response.data.deleteAccount }
  } catch (err) {
    return { error: err.errors[0].message }
  }
}

export const getAccount = async params => {
  const query = `
    query getAccount(
      $id: ID!
      $cognitoId: ID
    ) {
      getAccount(
        id: $id
        cognitoId: $cognitoId
      ) {
        id
        status
        type
        freeSecondsLeft
        paidSecondsLeft
        preferredTranscriptionLanguage
      }
    }
  `

  try {
    const operation = graphqlOperation(query, params)
    const response = await API.graphql(operation)
    return { account: response.data.getAccount }
  } catch (err) {
    return { error: I18n.get('Failed to get account.') }
  }
}

export const updateAccountPreferredTranscriptionLanguage = async params => {
  const mutation = `
    mutation updateAccountPreferredTranscriptionLanguage(
      $id: ID!
      $preferredTranscriptionLanguage: String!
    ) {
      updateAccountPreferredTranscriptionLanguage(
        id: $id
        preferredTranscriptionLanguage: $preferredTranscriptionLanguage
      ) {
        id
        status
        type
        freeSecondsLeft
        paidSecondsLeft
        preferredTranscriptionLanguage
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return {
      account: response.data.updateAccountPreferredTranscriptionLanguage
    }
  } catch (err) {
    return {
      error: I18n.get(
        'Failed to update account preferred transcription language.'
      )
    }
  }
}
