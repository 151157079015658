import { I18n } from 'aws-amplify'

const Footer = () => (
  <section className='mt-4 mb-2 px-4 pt-2 pb-4'>
    <div className='d-flex flex-column flex-md-row justify-content-start'>
      <span className='mr-4'>© 2020 - 2024</span>
      <a href='/terms-of-service' target='blank' className='mr-4'>
        {I18n.get('Terms of Service')}
      </a>
      <a href='/privacy-policy' target='blank'>
        {I18n.get('Privacy Policy')}
      </a>
    </div>
    <div className='d-flex justify-content-start my-2'>
      <p>
        Made by{' '}
        <a href='https://jagonzalr.com' target='blank'>
          jagonzalr
        </a>
        .
      </p>
    </div>
  </section>
)

export default Footer
