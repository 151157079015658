import { makeActionCreator } from '../utils'

export const LOADING = 'LOADING'
export const loading = makeActionCreator(LOADING, 'value')

export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const errorMessage = makeActionCreator(ERROR_MESSAGE, 'msg')

export const INSERT_TRANSCRIBE_STATUS_UPDATES =
  'INSERT_TRANSCRIBE_STATUS_UPDATES'
export const insertTranscribeStatusUpdates = makeActionCreator(
  INSERT_TRANSCRIBE_STATUS_UPDATES,
  'object'
)

export const REMOVE_TRANSCRIBE_STATUS_UPDATE = 'REMOVE_TRANSCRIBE_STATUS_UPDATE'
export const removeTranscribeStatusUpdate = makeActionCreator(
  REMOVE_TRANSCRIBE_STATUS_UPDATE,
  'id'
)

export const INSERT_TRANSLATE_STATUS_UPDATES = 'INSERT_TRANSLATE_STATUS_UPDATES'
export const insertTranslateStatusUpdates = makeActionCreator(
  INSERT_TRANSLATE_STATUS_UPDATES,
  'object'
)

export const REMOVE_TRANSLATE_STATUS_UPDATE = 'REMOVE_TRANSLATE_STATUS_UPDATE'
export const removeTranslateStatusUpdate = makeActionCreator(
  REMOVE_TRANSLATE_STATUS_UPDATE,
  'id'
)
