import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { BellIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/solid'

import { updateUserNotificationRead } from '../../actions/userNotification'
import { removeTranscribeStatusUpdate } from '../../actions/util'
import { getUserNotifications } from '../../reducers/selectors'
import { generateJobLink } from '../../utils'
import history from '../../history'

export default function BellNotifications() {
  const dispatch = useDispatch()
  const notifications = getUserNotifications()

  const [userNotifications, setUserNotifications] = useState([])

  useEffect(() => {
    setUserNotifications(notifications)
  }, [notifications])

  const goToJob = (e, accountId, creationTime, jobName, userId, read) => {
    e.preventDefault()
    history.push(generateJobLink(accountId, creationTime))
    dispatch(removeTranscribeStatusUpdate(jobName))
    if (!read) {
      dispatch(updateUserNotificationRead({ userId, creationTime }))
    }
  }

  return (
    <li className='nav-item dropdown'>
      <button
        className='btn nav-link dropdown-toggle btn-link notification-list arrow-none'
        id='navbarDropdownNotificationLink'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <BellIcon style={{ width: '1.2em' }} />
        {userNotifications.filter(({ read }) => !read).length > 0 && (
          <span className='noti-icon-badge' />
        )}
      </button>
      <div
        className='dropdown-menu dropdown-menu-right notification-list-menu'
        aria-labelledby='navbarDropdownNotificationLink'
      >
        {userNotifications.length === 0 && (
          <p className='dropdown-header'>
            {I18n.get('Up to date with all your job notifications.')}
          </p>
        )}
        {userNotifications.map(
          ({ accountId, creationTime, jobName, name, userId, read }, index) => (
            <button
              key={`${accountId}-${creationTime}-${index}`}
              className={classnames(
                'btn dropdown-item btn-link py-2 text-wrap',
                {
                  opaque: read
                }
              )}
              style={{ minWidth: '280px' }}
              data-toggle='collapse'
              data-target='.navbar-collapse.show'
              onClick={e =>
                goToJob(e, accountId, creationTime, jobName, userId, read)
              }
            >
              <ChatBubbleLeftEllipsisIcon
                className='mr-2'
                style={{ width: '1.2em' }}
              />
              {I18n.get('Your transcription')} <strong>{name}</strong>{' '}
              {I18n.get('is ready for review.')}
              <small
                className={classnames('d-block', {
                  'text-blue': !read
                })}
              >
                <strong>
                  {DateTime.fromMillis(creationTime).toRelative()}
                </strong>
              </small>
            </button>
          )
        )}
      </div>
    </li>
  )
}
