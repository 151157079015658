export const CHARGE_PER_REDACTION_MINUTES = 1
export const CHARGE_PER_SPEAKERS_MINUTES = 1
export const CHARGE_AUDIO_PER_MINUTE = 15
export const CHARGE_VIDEO_PER_MINUTE = 20
export const MINIMUM_CHARGE = 50
export const MAX_BYTES = 1000000000 * 2
export const MAX_BYTES_BASIC = 100000000 //100000000 * 2
export const MAX_SECONDS = 14400
export const MAX_SECOND_BASIC = 300 //3600 / 4

export const MINUTES_OPTIONS = [
  { value: 1000, label: '60 minutes' },
  { value: 2000, label: '120 minutes' },
  { value: 3000, label: '180 minutes' },
  { value: 4000, label: '240 minutes' },
  { value: 5000, label: '300 minutes' }
]

export const SUPPORTED_TRANSCRIBE_LANGUAGES = [
  { value: 'ab-GE', label: 'Abkhaz' },
  { value: 'af-ZA', label: 'Afrikaans' },
  { value: 'ar-AE', label: 'Gulf Arabic' },
  { value: 'ar-SA', label: 'Modern Standard Arabic' },
  { value: 'hy-AM', label: 'Armenian' },
  { value: 'ast-ES', label: 'Asturian' },
  { value: 'az-AZ', label: 'Azerbaijani' },
  { value: 'ba-RU', label: 'Bashkir' },
  { value: 'eu-ES', label: 'Basque' },
  { value: 'be-BY', label: 'Belarusian' },
  { value: 'bn-IN', label: 'Bengali' },
  { value: 'bs-BA', label: 'Bosnian' },
  { value: 'bg-BG', label: 'Bulgarian' },
  { value: 'ca-ES', label: 'Catalan' },
  { value: 'ckb-IR', label: 'Central Kurdish, Iran' },
  { value: 'ckb-IQ', label: 'Central Kurdish, Iraq' },
  { value: 'zh-CN', label: 'Chinese Simplified' },
  { value: 'zh-TW', label: 'Chinese Traditional' },
  { value: 'hr-HR', label: 'Croatian' },
  { value: 'cs-CZ', label: 'Czech' },
  { value: 'da-DK', label: 'Danish' },
  { value: 'nl-NL', label: 'Dutch' },
  { value: 'en-AU', label: 'English, Australian' },
  { value: 'en-GB', label: 'English, British' },
  { value: 'en-IN', label: 'English, Indian' },
  { value: 'en-IE', label: 'English, Irish' },
  { value: 'en-NZ', label: 'English, New Zealand' },
  { value: 'en-AB', label: 'English, Scottish' },
  { value: 'en-ZA', label: 'English, South African' },
  { value: 'en-US', label: 'English, US' },
  { value: 'en-WL', label: 'English, Welsh' },
  { value: 'et-ET', label: 'Estonian' },
  { value: 'fa-IR', label: 'Farsi' },
  { value: 'fi-FI', label: 'Finnish' },
  { value: 'fr-FR', label: 'French' },
  { value: 'fr-CA', label: 'French, Canadian' },
  { value: 'fa-IR', label: 'Galician' },
  { value: 'ka-GE', label: 'Georgian' },
  { value: 'de-DE', label: 'German' },
  { value: 'de-CH', label: 'German, Swiss' },
  { value: 'el-GR', label: 'Greek' },
  { value: 'gu-IN', label: 'Gujarati' },
  { value: 'ha-NG', label: 'Hausa' },
  { value: 'he-IL', label: 'Hebrew' },
  { value: 'hi-IN', label: 'Hindi, Indian' },
  { value: 'hu-HU', label: 'Hungarian' },
  { value: 'is-IS', label: 'Icelandic' },
  { value: 'id-ID', label: 'Indonesian' },
  { value: 'it-IT', label: 'Italian' },
  { value: 'ja-JP', label: 'Japanese' },
  { value: 'kab-DZ', label: 'Kabyle' },
  { value: 'kn-IN', label: 'Kannada' },
  { value: 'kk-KZ', label: 'Kazakh' },
  { value: 'rw-RW', label: 'Kinyarwanda' },
  { value: 'ko-KR', label: 'Korean' },
  { value: 'ky-KG', label: 'Kyrgyz' },
  { value: 'lv-LV', label: 'Latvian' },
  { value: 'lt-LT', label: 'Lithuanian' },
  { value: 'lg-IN', label: 'Luganda' },
  { value: 'mk-MK', label: 'Macedonian' },
  { value: 'ms-MY', label: 'Malay' },
  { value: 'ml-IN', label: 'Malayalam' },
  { value: 'mt-MT', label: 'Maltese' },
  { value: 'mr-IN', label: 'Marathi' },
  { value: 'mhr-RU', label: 'Meadow Mari' },
  { value: 'mn-MN', label: 'Mongolian' },
  { value: 'no-NO', label: 'Norwegian Bokmål' },
  { value: 'or-IN', label: 'Odia/Oriya' },
  { value: 'ps-AF', label: 'Pashto' },
  { value: 'pl-PL', label: 'Polish' },
  { value: 'pt-PT', label: 'Portuguese' },
  { value: 'pt-BR', label: 'Portuguese, Brazilian' },
  { value: 'pa-IN', label: 'Punjabi' },
  { value: 'ro-RO', label: 'Romanian' },
  { value: 'ru-RU', label: 'Russian' },
  { value: 'sr-RS', label: 'Serbian' },
  { value: 'si-LK', label: 'Sinhala' },
  { value: 'sk-SK', label: 'Slovak' },
  { value: 'sl-SI', label: 'Slovenian' },
  { value: 'so-SO', label: 'Somali' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'es-US', label: 'Spanish, US' },
  { value: 'su-ID', label: 'Sundanese' },
  { value: 'sw-KE', label: 'Swahili, Kenya' },
  { value: 'sw-BI', label: 'Swahili, Burundi' },
  { value: 'sw-RW', label: 'Swahili, Rwanda' },
  { value: 'sw-TZ', label: 'Swahili, Tanzania' },
  { value: '	sw-UG', label: 'Swahili, Uganda' },
  { value: 'sv-SE', label: 'Swedish' },
  { value: 'tl-PH', label: 'Tagalog/Filipino' },
  { value: 'ta-IN', label: 'Tamil' },
  { value: 'tt-RU', label: 'Tatar' },
  { value: 'te-IN', label: 'Telugu' },
  { value: 'th-TH', label: 'Thai' },
  { value: 'tr-TR', label: 'Turkish' },
  { value: 'uk-UA', label: 'Ukrainian' },
  { value: 'ug-CN', label: 'Uyghur' },
  { value: 'uz-UZ', label: 'Uzbek' },
  { value: 'vi-VN', label: 'Vietnamese' },
  { value: 'cy-WL', label: 'Welsh' },
  { value: 'wo-SN', label: 'Wolof' },
  { value: 'zu-ZA', label: 'Zulu' }
]

export const TRANSLATE_LANGUAGES = [
  { value: 'af', label: 'Afrikaans' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'hy', label: 'Armenian' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'zh', label: 'Chinese (Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'fa-AF', label: 'Dari' },
  { value: 'nl', label: 'Dutch' },
  { value: 'en', label: 'English' },
  { value: 'et', label: 'Estonian' },
  { value: 'fa', label: 'Farsi (Persian)' },
  { value: 'tl', label: 'Filipino Tagalog' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian Creole' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'ko', label: 'Korean' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'no', label: 'Norwegian' },
  { value: 'fa', label: 'Persian' },
  { value: 'ps', label: 'Pashto' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'es', label: 'Spanish' },
  { value: 'es-MX', label: 'Spanish (Mexico)' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sv', label: 'Swedish' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'cy', label: 'Welsh' }
]

export const SUPPORTED_TRANSLATE_LANGUAGES = TRANSLATE_LANGUAGES.filter(
  lang => {
    return SUPPORTED_TRANSCRIBE_LANGUAGES.some(transcribeLang => {
      return transcribeLang.value.includes(lang.value)
    })
  }
)

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

export const buildTopic = topic => `/${ENVIRONMENT}/${topic}`

export const capitalizeWord = str => {
  if (str) return str[0].toUpperCase() + str.slice(1)
  return str
}

export const cleanupParagraphs = paragraphs => {
  if (!paragraphs) return []
  return paragraphs.reduce((acc, paragraph) => {
    let children = [...paragraph.children].map(child => {
      return child.text ? child : Object.assign({}, child, { text: '' })
    })

    paragraph.children = children
    acc.push(paragraph)
    return acc
  }, [])
}

// https://medium.com/better-programming/write-cleaner-reducers-1c433a94a166
export function createReducer(handlers, initialState = {}) {
  return function (state = initialState, action) {
    const handler = handlers[action.type]
    if (!handler) {
      return state
    }

    const nextState = handler(state, action)
    return nextState
  }
}

export const customizeSelectTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#E1E0E0',
      primary50: '#CECECE',
      primary: '#3f47ec'
    }
  }
}

export const delay = (ms = 1000) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const generateDownloadLink = (file, title, ext = 'txt') => {
  const encodedUri = encodeURI(file)
  let link = document.createElement('a')
  link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodedUri)
  link.setAttribute('download', `${title}.${ext}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const generateJobLink = (accountId, creationTime) => {
  return `/app/jobs/${window.btoa(`${accountId}___${creationTime}`)}`
}

export const generateSubtitles = (value, isWebVtt = false) => {
  let file = ''
  let lines = []
  let previousTime = 0
  let currentTime = 0

  const paragraphs = value.reduce((acc, cur) => {
    if (cur.type === 'paragraph') {
      acc.push(cur.children)
    }
    return acc
  }, [])

  paragraphs.forEach(paragraph => {
    let currentIndex = 0
    paragraph.forEach((word, index) => {
      if (currentTime - previousTime > 6 || index + 1 === paragraph.length) {
        const newLine = paragraph.slice(currentIndex, index + 1)
        lines.push(newLine)
        previousTime = parseFloat(currentTime)
        currentIndex = index + 1
      } else {
        currentTime = parseFloat(word.start)
      }
    })
  })

  if (isWebVtt) file = 'WEBVTT\n\n'

  file += lines.reduce((acc, line, index) => {
    acc += `${index + 1}\n`
    acc += `${parseTime(parseFloat(line[0].start)).replace(
      '.',
      ','
    )} --> ${parseTime(parseFloat(line[line.length - 1].end)).replace(
      '.',
      ','
    )}\n`
    acc += `${line.map(({ text }) => text).join('')}\n`
    acc += '\n'
    return acc
  }, '')

  return file
}

export const generateTranscript = (value, withTimestamps = false) => {
  let file = ''

  const paragraphs = value.reduce((acc, cur) => {
    if (cur.type === 'paragraph') {
      acc.push(cur.children)
    }
    return acc
  }, [])

  paragraphs.forEach(paragraph => {
    if (withTimestamps) {
      file += `${parseTime(parseFloat(paragraph[0].start))} --> ${parseTime(
        parseFloat(paragraph[paragraph.length - 1].end)
      )}\n`
    }

    file += paragraph.map(({ text }) => text).join('') + '\n\n'
  })

  return file
}

export const getFileDuration = url => {
  return new Promise((resolve, reject) => {
    const obj = new Audio(url)
    obj.addEventListener('canplaythrough', event => {
      const duration = Math.ceil(obj.duration)
      resolve(duration)
    })
  })
}

export const getFileFormat = type => {
  const format = type.split('/')[1]
  if (format === 'mpeg') return 'mp3'
  return format
}

export const getTranslationText = async (key, url) => {
  try {
    const response = await fetch(url)
    const text = await response.text()
    return { key, text }
  } catch (err) {
    throw err
  }
}

export const isValidFileType = type =>
  type.includes('audio/mpeg') ||
  type.includes('audio/wav') ||
  type.includes('audio/mpeg') ||
  type.includes('audio/flac') ||
  type.includes('video/mp4')

// https://github.com/reduxjs/redux/blob/master/docs/recipes/ReducingBoilerplate.md
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })

    return action
  }
}

export function paginate(array, pageSize, pageNumber) {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

export const parseBytes = bytes => {
  if (bytes < 1000000) {
    return `${parseFloat(bytes / 1000).toFixed(2)} kb`
  }

  if (bytes < 1000000000) {
    return `${parseFloat(bytes / 1000000).toFixed(2)} mb`
  }

  return `${parseFloat(bytes / 1000000000).toFixed(2)} gb`
}

export const parseSeconds = seconds => {
  if (seconds < 60) {
    return `${parseFloat(seconds).toFixed(2)} seconds`
  }

  return `${parseFloat(seconds / 60).toFixed(2)} minutes`
  // if (seconds < 3600) {
  //   return `${parseFloat(seconds / 60).toFixed(2)} minutes`
  // }

  // return `${parseFloat(seconds / 3600).toFixed(2)} hours`
}

export const parseTime = seconds => {
  if (seconds < 60) {
    return `00:00:${
      seconds < 10
        ? `0${parseFloat(seconds).toFixed(3)}`
        : parseFloat(seconds).toFixed(3)
    }`
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60)
    const reminder = parseFloat(seconds % 60).toFixed(3)
    return `00:${minutes < 10 ? `0${minutes}` : minutes}:${
      reminder < 10 ? `0${reminder}` : reminder
    }`
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) - hours * 60
  const reminder = parseFloat(seconds % 60).toFixed(3)
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${reminder < 10 ? `0${reminder}` : reminder}`
}

export const readFileAsync = file => URL.createObjectURL(file)

export const sortTraslationsByName = translations => {
  translations.sort((a, b) => {
    const aLang = TRANSLATE_LANGUAGES.find(
      ({ value }) => value === a.key
    )?.label
    const bLang = TRANSLATE_LANGUAGES.find(
      ({ value }) => value === b.key
    )?.label

    if (aLang > bLang) return 1
    if (aLang < bLang) return -1
    return 0
  })

  return translations
}

export const validFile = (file, accountType, hasPaidSeconds) => {
  return (
    validSecondsLength(file.duration, accountType, hasPaidSeconds) &&
    validBytesLength(file.file.size, accountType, hasPaidSeconds)
  )
}

export const validSecondsLength = (seconds, accountType, hasPaidSeconds) => {
  //TODO: implement account type change when paying for the first time
  // const maxSeconds = accountType === 'basic' ? MAX_SECOND_BASIC : MAX_SECONDS
  const maxSeconds = !hasPaidSeconds ? MAX_SECOND_BASIC : MAX_SECONDS
  return seconds < maxSeconds
}

export const validBytesLength = (bytes, accountType, hasPaidSeconds) => {
  //TODO: implement account type change when paying for the first time
  // const maxBytes = accountType === 'basic' ? MAX_BYTES_BASIC : MAX_BYTES
  const maxBytes = !hasPaidSeconds ? MAX_BYTES_BASIC : MAX_BYTES
  return bytes < maxBytes
}
