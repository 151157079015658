import { makeActionCreator } from '../utils'

export const CREATE_STRIPE_CHECKOUT_SESSION = 'CREATE_STRIPE_CHECKOUT_SESSION'
export const STRIPE_CHECKOUT_SESSION_CREATED = 'STRIPE_CHECKOUT_SESSION_CREATED'
export const createStripeCheckoutSession = makeActionCreator(
  CREATE_STRIPE_CHECKOUT_SESSION,
  'params'
)
export const stripeCheckoutSessionCreated = makeActionCreator(
  STRIPE_CHECKOUT_SESSION_CREATED,
  'url',
  'error'
)

export const UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT =
  'UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT'
export const ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT_UPDATED =
  'ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT_UPDATED'
export const updateAccountBalanceWithStripeCheckout = makeActionCreator(
  UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT,
  'params'
)
export const accountBalanceWithStripeCheckoutUpdated = makeActionCreator(
  ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT_UPDATED,
  'account',
  'error'
)
