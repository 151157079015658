import { call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_COGNITO_USER,
  cognitoUserFetched,
  UPDATE_EMAIL_NOTIFICATIONS,
  emailNotificationsUpdated
} from '../actions/user'
import { loading } from '../actions/util'
import { getCognitoUser, updateEmailNotifications } from '../api/user'
import { cleanup, forceSignOut } from './init'

export function* fetchCognitoUser(action) {
  const { user, error } = yield call(getCognitoUser)
  yield put(cognitoUserFetched(user, error))
  if (error) {
    yield put(loading(false))
    yield call(forceSignOut)
    yield call(cleanup)
  }
}

export function* watchGetCognitoUser() {
  yield takeLatest(FETCH_COGNITO_USER, fetchCognitoUser)
}

export function* sendUpdateEmailNotifications(action) {
  const { user, error } = yield call(updateEmailNotifications, action.params)
  yield put(emailNotificationsUpdated(user, error))
}

export function* watchUpdateEmailNotifications() {
  yield takeLatest(UPDATE_EMAIL_NOTIFICATIONS, sendUpdateEmailNotifications)
}
