import { Auth, Cache, I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import {
  UserIcon,
  WrenchIcon,
  ArrowLeftEndOnRectangleIcon
} from '@heroicons/react/24/solid'

import { cleanAccount } from '../../actions/account'
import { cleanJob, cleanJobs } from '../../actions/job'
import { cleanCognitoUser, cleanCurrentUser } from '../../actions/user'
import { cleanUserNotifications } from '../../actions/userNotification'
import { getCognitoUser, getCurrentUser } from '../../reducers/selectors'
import history from '../../history'

export default function DropdownMenu() {
  const dispatch = useDispatch()
  const cognitoUser = getCognitoUser()
  const currentUser = getCurrentUser()

  const logout = async () => {
    Cache.clear()
    await Auth.signOut()
    dispatch(cleanAccount())
    dispatch(cleanJob())
    dispatch(cleanJobs())
    dispatch(cleanCognitoUser())
    dispatch(cleanCurrentUser())
    dispatch(cleanUserNotifications())
    history.push('/')
  }

  return (
    <li className='nav-item dropdown'>
      <button
        className='btn nav-link dropdown-toggle btn-link'
        id='navbarDropdownMenuLink'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {cognitoUser?.attributes?.email}
      </button>
      <div
        className='dropdown-menu dropdown-menu-right'
        aria-labelledby='navbarDropdownMenuLink'
      >
        <button
          className='btn dropdown-item btn-link'
          data-toggle='collapse'
          data-target='.navbar-collapse.show'
          onClick={() => history.push('/app/profile')}
        >
          <UserIcon className='mr-1' style={{ width: '1.2em' }} />
          {I18n.get('Profile')}
        </button>
        {currentUser.type === 'admin' && (
          <button
            className='btn dropdown-item btn-link'
            data-toggle='collapse'
            data-target='.navbar-collapse.show'
            onClick={() => history.push('/app/settings')}
          >
            <WrenchIcon className='mr-1' style={{ width: '1.2em' }} />
            {I18n.get('Settings')}
          </button>
        )}
        <button className='btn dropdown-item btn-link' onClick={() => logout()}>
          <ArrowLeftEndOnRectangleIcon
            className='mr-1'
            style={{ width: '1.2em' }}
          />
          {I18n.get('Logout')}
        </button>
      </div>
    </li>
  )
}
