import {
  CLEAN_USER_NOTIFICATIONS,
  USER_NOTIFICATIONS_FETCHED,
  UPDATE_USER_NOTIFICATIONS_LIST,
  USER_NOTIFICATIONS_READ_UPDATED
} from '../actions/userNotification'

import { createReducer } from '../utils'

const initialState = {
  notifications: []
}

const handlers = {
  [CLEAN_USER_NOTIFICATIONS]: (state, action) => ({
    ...state,
    notifications: []
  }),
  [USER_NOTIFICATIONS_FETCHED]: (state, action) => ({
    ...state,
    notifications: !action.error ? action.notifications : []
  }),
  [UPDATE_USER_NOTIFICATIONS_LIST]: (state, action) => ({
    ...state,
    notifications: insertNotifcationIntoList(
      [...state.notifications],
      action.notification
    )
  }),
  [USER_NOTIFICATIONS_READ_UPDATED]: (state, action) => ({
    ...state,
    notifications: updateUserNotificationsList(
      [...state.notifications],
      action.notification
    )
  })
}

export default createReducer(handlers, initialState)

const insertNotifcationIntoList = (notifications, notification) => {
  notifications.unshift(notification)
  return [...new Set(notifications)]
}

const updateUserNotificationsList = (notifications, notification) => {
  const index = notifications.findIndex(
    ({ userId, creationTime }) =>
      userId === notification?.userId &&
      creationTime === notification?.creationTime
  )

  if (index > -1) {
    notifications[index] = notification
  }

  return notifications
}
