import { makeActionCreator } from '../utils'

export const CLEAN_USER_NOTIFICATIONS = 'CLEAN_USER_NOTIFICATIONS'
export const cleanUserNotifications = makeActionCreator(
  CLEAN_USER_NOTIFICATIONS
)

export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS'
export const USER_NOTIFICATIONS_FETCHED = 'USER_NOTIFICATIONS_FETCHED'
export const fetchUserNotifications = makeActionCreator(
  FETCH_USER_NOTIFICATIONS,
  'params'
)
export const userNotificationsFetched = makeActionCreator(
  USER_NOTIFICATIONS_FETCHED,
  'notifications',
  'error'
)

export const UPDATE_USER_NOTIFICATION_READ = 'UPDATE_USER_NOTIFICATION_READ'
export const USER_NOTIFICATIONS_READ_UPDATED = 'USER_NOTIFICATIONS_READ_UPDATED'
export const updateUserNotificationRead = makeActionCreator(
  UPDATE_USER_NOTIFICATION_READ,
  'params'
)
export const userNotificationsReadUpdated = makeActionCreator(
  USER_NOTIFICATIONS_READ_UPDATED,
  'notification',
  'error'
)

export const UPDATE_USER_NOTIFICATIONS_LIST = 'UPDATE_USER_NOTIFICATIONS_LIST'
export const updateUserNotificationsList = makeActionCreator(
  UPDATE_USER_NOTIFICATIONS_LIST,
  'notification'
)
