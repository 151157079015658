import { makeActionCreator } from '../utils'

export const CLEAN_COGNITO_USER = 'CLEAN_COGNITO_USER'
export const cleanCognitoUser = makeActionCreator(CLEAN_COGNITO_USER)

export const CLEAN_CURRENT_USER = 'CLEAN_CURRENT_USER'
export const cleanCurrentUser = makeActionCreator(CLEAN_CURRENT_USER)

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const CURRENT_USER_FETCHED = 'CURRENT_USER_FETCHED'
export const fetchCurrentUser = makeActionCreator(FETCH_CURRENT_USER)
export const currentUserFetched = makeActionCreator(
  CURRENT_USER_FETCHED,
  'user',
  'error'
)

export const FETCH_COGNITO_USER = 'FETCH_COGNITO_USER'
export const COGNITO_USER_FETCHED = 'COGNITO_USER_FETCHED'
export const fetchCognitoUser = makeActionCreator(FETCH_COGNITO_USER)
export const cognitoUserFetched = makeActionCreator(
  COGNITO_USER_FETCHED,
  'user',
  'error'
)

export const UPDATE_EMAIL_NOTIFICATIONS = 'UPDATE_EMAIL_NOTIFICATIONS'
export const EMAIL_NOTIFICATIONS_UPDATED = 'EMAIL_NOTIFICATIONS_UPDATED'
export const updateEmailNotifications = makeActionCreator(
  UPDATE_EMAIL_NOTIFICATIONS,
  'params'
)
export const emailNotificationsUpdated = makeActionCreator(
  EMAIL_NOTIFICATIONS_UPDATED,
  'user',
  'error'
)
