/* eslint-disable */
import { useSelector } from 'react-redux'

export const getAccount = () => {
  return getStateWithSelector('accountReducer', 'account')
}

export const getAccountIsSaving = () => {
  return getStateWithSelector('accountReducer', 'isSaving')
}

export const getJobs = () => {
  return getStateWithSelector('jobReducer', 'jobs')
}

export const getJob = () => {
  return getStateWithSelector('jobReducer', 'job')
}

export const getJobIsSaving = () => {
  return getStateWithSelector('jobReducer', 'isSaving')
}

export const getJobIsStartingTranslation = () => {
  return getStateWithSelector('jobReducer', 'isStartingTranslation')
}

export const getJobIsTranslating = () => {
  return getStateWithSelector('jobReducer', 'isTranslating')
}

export const getStripeCheckoutSessionUrl = () => {
  return getStateWithSelector('stripeReducer', 'url')
}

export const getCognitoUser = () => {
  return getStateWithSelector('userReducer', 'cognitoUser')
}

export const getCurrentUser = () => {
  return getStateWithSelector('userReducer', 'currentUser')
}

export const getEmailNotificationsSaving = () => {
  return getStateWithSelector('userReducer', 'emailNotificationsSaving')
}

export const getUserNotifications = () => {
  return getStateWithSelector('userNotificationReducer', 'notifications')
}

export const getLoading = () => {
  return getStateWithSelector('utilReducer', 'loading')
}

export const getErrorMessage = () => {
  return getStateWithSelector('utilReducer', 'errorMessage')
}

export const getUpdates = () => {
  return getStateWithSelector('utilReducer', 'updates')
}

export const getTranslationUpdates = () => {
  return getStateWithSelector('utilReducer', 'translationUpdates')
}

const getStateWithSelector = (reducer, value) => {
  return useSelector(state => state.rootReducer[reducer][value])
}
