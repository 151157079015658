import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { Amplify } from 'aws-amplify'
import 'bootstrap'

import { AuthProvider } from './contexts/auth-context'
import history from './history'
import reportWebVitals from './reportWebVitals'
import routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import configureStore from './store'

import {
  REGION,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_POOL_ID,
  COGNITO_POOL_WEB_CLIENT_ID,
  GRAPHQL_ENDPOINT,
  S3_BUCKET
} from './config'

import './assets/styles/custom.scss'
import './assets/styles/index.scss'

Amplify.configure({
  Auth: {
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: REGION,
    userPoolId: COGNITO_POOL_ID,
    userPoolWebClientId: COGNITO_POOL_WEB_CLIENT_ID
  },
  API: {
    graphql_endpoint: GRAPHQL_ENDPOINT,
    graphql_endpoint_iam_region: REGION
  },
  Storage: {
    AWSS3: {
      bucket: S3_BUCKET,
      region: REGION
    }
  }
})

const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <AuthProvider>
      <Router history={history}>
        <Suspense fallback={<span />}>{renderRoutes(routes)}</Suspense>
      </Router>
    </AuthProvider>
  </Provider>
)

serviceWorkerRegistration.register()

reportWebVitals()
