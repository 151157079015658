import { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

import BellNotifications from './BellNotifications'
import DropdownMenu from './DropdownMenu'
import LoginButton from './LoginButton'
import Logo from './Logo'
import Menu from './Menu'
import ToggleButton from './ToggleButton'
import Usage from './Usage'

import { getCognitoUser } from '../../reducers/selectors'

function showMenuItems(pathname) {
  return (
    pathname !== '/' &&
    pathname !== '/terms-of-service' &&
    pathname !== '/privacy-policy' &&
    pathname !== '/supported-languages'
  )
}

const Navbar = ({ location }) => {
  const cognitoUser = getCognitoUser()

  return (
    <nav className='navbar navbar-expand-lg navbar-light'>
      <Logo />
      <ToggleButton />
      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav mx-auto'>
          {cognitoUser.hasOwnProperty('username') &&
            showMenuItems(location.pathname) && <Menu location={location} />}
        </ul>
        <ul className='navbar-nav d-flex'>
          {cognitoUser.hasOwnProperty('username') &&
            showMenuItems(location.pathname) && (
              <Fragment>
                <Usage />
                <BellNotifications />
                <DropdownMenu />
              </Fragment>
            )}
          {location.pathname === '/' && (
            <li className='nav-item'>
              <a
                href='https://www.producthunt.com/posts/transcribe-ninja?utm_source=badge-review&utm_medium=badge&utm_souce=badge-transcribe-ninja#discussion-body'
                target='blank'
                rel='noopener'
                className='mr-2'
              >
                <img
                  src='https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=286466&theme=light'
                  alt='Transcribe Ninja - Create AI-generated transcripts in minutes. | Product Hunt'
                  style={{ width: '190px', height: '41px' }}
                  width='19'
                  height='41'
                />
              </a>
            </li>
          )}
          {!showMenuItems(location.pathname) && <LoginButton />}
        </ul>
      </div>
    </nav>
  )
}

export default withRouter(Navbar)
