import {
  JOBS_FETCHED,
  JOB_FETCHED,
  SET_JOB,
  CLEAN_JOB,
  CLEAN_JOBS,
  UPDATE_JOB_NAME,
  JOB_NAME_UPDATED,
  RESET_JOB_TRANSCRIPT,
  JOB_TRANSCRIPT_RESET,
  START_TRANSLATION,
  TRANSLATION_STARTED,
  TRANSLATION_FINISHED,
  UPDATE_JOB_PARAGRAPHS,
  JOB_PARAGRAPHS_UPDATED,
  UPDATE_JOBS_LIST
} from '../actions/job'

import { createReducer } from '../utils'

const initialState = {
  jobs: [],
  job: {},
  isSaving: false,
  isStartingTranslation: false,
  isTranslating: false
}

const handlers = {
  [JOBS_FETCHED]: (state, action) => ({
    ...state,
    jobs: !action.error ? action.jobs : []
  }),
  [JOB_FETCHED]: (state, action) => ({
    ...state,
    job: !action.error ? action.job : {}
  }),
  [SET_JOB]: (state, action) => ({ ...state, job: action.job }),
  [CLEAN_JOB]: (state, action) => ({ ...state, job: {} }),
  [CLEAN_JOBS]: (state, action) => ({ ...state, jobs: [] }),
  [UPDATE_JOB_NAME]: (state, action) => ({ ...state, isSaving: true }),
  [UPDATE_JOB_PARAGRAPHS]: (state, action) => ({ ...state, isSaving: true }),
  [RESET_JOB_TRANSCRIPT]: (state, action) => ({ ...state, isSaving: true }),
  [JOB_TRANSCRIPT_RESET]: (state, action) => ({
    ...state,
    isSaving: false,
    job: !action.error ? { ...state.job, ...action.job } : state.job,
    jobs: updateJobsList([...state.jobs], action.job)
  }),
  [START_TRANSLATION]: (state, action) => ({
    ...state,
    isStartingTranslation: true
  }),
  [TRANSLATION_STARTED]: (state, action) => ({
    ...state,
    isStartingTranslation: false,
    isTranslating: true
  }),
  [TRANSLATION_FINISHED]: (state, action) => ({
    ...state,
    isTranslating: false
  }),
  [JOB_PARAGRAPHS_UPDATED]: (state, action) => ({
    ...state,
    isSaving: false,
    job: !action.error ? { ...state.job, ...action.job } : state.job,
    jobs: updateJobsList([...state.jobs], action.job)
  }),
  [JOB_NAME_UPDATED]: (state, action) => ({
    ...state,
    isSaving: false,
    job: !action.error ? { ...state.job, ...action.job } : state.job,
    jobs: updateJobsList([...state.jobs], action.job)
  }),
  [UPDATE_JOBS_LIST]: (state, action) => ({
    ...state,
    job: !action.error ? { ...state.job, ...action.job } : state.job,
    jobs: updateJobsList([...state.jobs], action.job, true)
  })
}

export default createReducer(handlers, initialState)

const updateJobsList = (jobs, job, toBeginning = false) => {
  const index = jobs.findIndex(({ jobName }) => jobName === job?.jobName)
  if (index > -1) {
    jobs[index] = { ...jobs[index], ...job }
  } else if (toBeginning) {
    jobs.unshift(job)
  } else {
    jobs.push(job)
  }

  return jobs
}
