import { createContext } from 'react'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'

import rootReducer from './reducers'
import rootSaga from './sagas'

let loggerMiddleware = null
if (process.env.NODE_ENV === 'development') {
  loggerMiddleware = require('redux-logger').createLogger()
}

const sagaMiddleware = createSagaMiddleware()

let store = null
export let storeContext = createContext({})

export default () => {
  const middleware = [sagaMiddleware, loggerMiddleware].filter(Boolean)

  store = createStore(
    combineReducers({
      rootReducer
    }),
    applyMiddleware(...middleware)
  )

  sagaMiddleware.run(rootSaga)
  store.close = () => store.dispatch(END)
  storeContext = createContext(store)

  return store
}

export const dispatch = action => {
  return action ? store.dispatch(action) : null
}
