import { call, put, takeLatest } from 'redux-saga/effects'

import { accountFetched } from '../actions/account'
import {
  CREATE_STRIPE_CHECKOUT_SESSION,
  stripeCheckoutSessionCreated,
  UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT,
  accountBalanceWithStripeCheckoutUpdated
} from '../actions/stripe'
import {
  createStripeCheckoutSession,
  updateAccountBalanceWithStripeCheckout
} from '../api/stripe'

export function* sendCreateStripeCheckoutSession(action) {
  const { url, error } = yield call(createStripeCheckoutSession, action.params)
  yield put(stripeCheckoutSessionCreated(url, error))
}

export function* watchCreateStripeCheckoutSession() {
  yield takeLatest(
    CREATE_STRIPE_CHECKOUT_SESSION,
    sendCreateStripeCheckoutSession
  )
}

export function* sendUpdateAccountBalanceWithStripeCheckout(action) {
  const { account, error } = yield call(
    updateAccountBalanceWithStripeCheckout,
    action.params
  )
  yield put(accountFetched(account, error))
  yield put(accountBalanceWithStripeCheckoutUpdated(account, error))
}

export function* watchUpdateAccountBalanceWithStripeCheckout() {
  yield takeLatest(
    UPDATE_ACCOUNT_BALANCE_WITH_STRIPE_CHECKOUT,
    sendUpdateAccountBalanceWithStripeCheckout
  )
}
