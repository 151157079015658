import { Fragment } from 'react'
import { I18n } from 'aws-amplify'
import classnames from 'classnames'

import { getAccount, getCurrentUser } from '../../reducers/selectors'
import history from '../../history'

export default function Menu({ location }) {
  const account = getAccount()
  const currentUser = getCurrentUser()

  return (
    <Fragment>
      <li
        className={classnames('nav-item', {
          active: location.pathname === '/app'
        })}
      >
        <button
          className='btn nav-link'
          data-toggle='collapse'
          data-target='.navbar-collapse.show'
          onClick={() => history.push('/app')}
        >
          <span>{I18n.get('Upload')}</span>
        </button>
      </li>
      <li
        className={classnames('nav-item', {
          active: location.pathname.includes('/app/jobs')
        })}
      >
        <button
          className='btn nav-link'
          data-toggle='collapse'
          data-target='.navbar-collapse.show'
          onClick={() => history.push('/app/jobs')}
        >
          <span>{I18n.get('Jobs')}</span>
        </button>
      </li>
      {(currentUser.type === 'admin' || currentUser.type === 'staff') &&
        account.type !== 'basic' && (
          <li
            className={classnames('nav-item', {
              active: location.pathname.includes('/app/team')
            })}
          >
            <button
              className='btn nav-link'
              data-toggle='collapse'
              data-target='.navbar-collapse.show'
              onClick={() => history.push('/app/team')}
            >
              <span>{I18n.get('Team')}</span>
            </button>
          </li>
        )}
    </Fragment>
  )
}
