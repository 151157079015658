import { I18n } from 'aws-amplify'
import { ChartPieIcon } from '@heroicons/react/24/solid'

import { getAccount } from '../../reducers/selectors'
import { parseSeconds } from '../../utils'

const renderMinutes = account => {
  const seconds =
    account?.paidSecondsLeft > 0
      ? account?.paidSecondsLeft
      : account.freeSecondsLeft
  return (
    <strong className='text-left d-block my-1'>
      {parseSeconds(seconds)} left
    </strong>
  )
}

const Usage = () => {
  const account = getAccount()

  return (
    <li className='nav-item dropdown'>
      <button
        className='btn nav-link dropdown-toggle btn-link notification-list arrow-none'
        id='navbarDropdownUsageLink'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <ChartPieIcon style={{ width: '1.2em' }} />
      </button>
      <div
        className='dropdown-menu dropdown-menu-right notification-list-menu p-2'
        aria-labelledby='navbarDropdownUsageLink'
      >
        <p className='dropdown-header usage-header'>
          {I18n.get('Transcriptions')}
        </p>
        <p className='usage-item' style={{ minWidth: '200px' }}>
          {renderMinutes(account)}
        </p>
      </div>
    </li>
  )
}

export default Usage
