import { combineReducers } from 'redux'

import accountReducer from './account'
import jobReducer from './job'
import stripeReducer from './stripe'
import userReducer from './user'
import userNotificationReducer from './userNotification'
import utilReducer from './util'

export default combineReducers({
  accountReducer,
  jobReducer,
  stripeReducer,
  userReducer,
  userNotificationReducer,
  utilReducer
})
