import { call, put, takeLatest } from 'redux-saga/effects'

import { cleanup, forceSignOut } from './init'
import {
  FETCH_ACCOUNT,
  accountFetched,
  UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE,
  accountPreferredTranscriptionLanguageUpdated,
  DELETE_ACCOUNT
} from '../actions/account'
import { loading, errorMessage } from '../actions/util'
import {
  getAccount,
  updateAccountPreferredTranscriptionLanguage,
  deleteAccount
} from '../api/account'

import history from '../history'

export function* fetchAccount(action) {
  const { account, error } = yield call(getAccount, action.params)
  yield put(accountFetched(account, error))
}

export function* watchGetAccount() {
  yield takeLatest(FETCH_ACCOUNT, fetchAccount)
}

export function* sendUpdateAccountPreferredLanguage(action) {
  const { account, error } = yield call(
    updateAccountPreferredTranscriptionLanguage,
    action.params
  )
  yield put(accountPreferredTranscriptionLanguageUpdated(account, error))
}

export function* watchUpdateAccountPreferredLanguage() {
  yield takeLatest(
    UPDATE_ACCOUNT_PREFERRED_TRANSCRIPTION_LANGUAGE,
    sendUpdateAccountPreferredLanguage
  )
}

export function* sendDeleteAccount(action) {
  yield put(loading(true))
  const { error } = yield call(deleteAccount, action.params)
  yield put(loading(false))
  if (!error) {
    yield call(forceSignOut)
    yield call(cleanup)
    history.push('/')
  } else {
    yield put(errorMessage(error))
  }
}

export function* watchDeleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, sendDeleteAccount)
}
