export default () => (
  <button
    className='navbar-toggler'
    type='button'
    data-toggle='collapse'
    data-target='#navbarSupportedContent'
    aria-controls='navbarSupportedContent'
    aria-expanded='false'
    aria-label='Toggle navigation'
  >
    <span className='navbar-toggler-icon'></span>
  </button>
)
