import { API, I18n, graphqlOperation } from 'aws-amplify'

export const getJobs = async params => {
  const query = `
    query getJobs(
      $accountId: ID!
      $cognitoId: ID
    ) {
      getJobs(
        accountId: $accountId
        cognitoId: $cognitoId
      ) {
        accountId
        creationTime
        duration
        inputKey
        jobName
        languageCode
        name
        paragraphsKey
        translationsLang
        status
      }
    }
  `

  try {
    const operation = graphqlOperation(query, params)
    const response = await API.graphql(operation)
    return { jobs: response.data.getJobs }
  } catch (err) {
    return { error: I18n.get('Failed to get jobs.') }
  }
}

export const getJob = async params => {
  const query = `
    query getJob(
      $jobId: String!
      $cognitoId: ID
    ) {
      getJob(
        jobId: $jobId
        cognitoId: $cognitoId
      ) {
        accountId
        creationTime
        duration
        inputKey
        jobName
        languageCode
        name
        nonRedactedParagraphs
        paragraphsKey
        paragraphs
        translations
        status
      }
    }
  `

  try {
    const operation = graphqlOperation(query, params)
    const response = await API.graphql(operation)
    return { job: response.data.getJob }
  } catch (err) {
    return { error: I18n.get('Failed to get job.') }
  }
}

export const resetJobTranscript = async params => {
  const mutation = `
    mutation resetJobTranscript(
      $jobId: String!
      $cognitoId: ID
    ) {
      resetJobTranscript(
        jobId: $jobId
        cognitoId: $cognitoId
      ) {
        accountId
        creationTime
        duration
        inputKey
        jobName
        languageCode
        name
        paragraphsKey
        paragraphs
        status
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { job: response.data.resetJobTranscript }
  } catch (err) {
    return { error: I18n.get('Failed to reset transcript.') }
  }
}

export const startTranslation = async params => {
  const mutation = `
    mutation startTranslation(
      $jobId: String!
      $cognitoId: ID
      $languages: [String]
    ) {
      startTranslation(
        jobId: $jobId
        cognitoId: $cognitoId
        languages: $languages
      ) {
        accountId
        creationTime
        jobName
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { job: response.data.startTranslation }
  } catch (err) {
    return { error: err.errors[0].message }
  }
}

export const updateJobName = async params => {
  const mutation = `
    mutation updateJobName(
      $jobId: String!
      $cognitoId: ID
      $name: String
    ) {
      updateJobName(
        jobId: $jobId
        cognitoId: $cognitoId
        name: $name
      ) {
        accountId
        creationTime
        duration
        inputKey
        jobName
        languageCode
        name
        paragraphsKey
        status
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { job: response.data.updateJobName }
  } catch (err) {
    return { error: I18n.get('Failed to update job.') }
  }
}

export const updateJobParagraphs = async params => {
  const mutation = `
    mutation updateJobParagraphs(
      $jobId: String!
      $cognitoId: ID
      $paragraphs: GraphQLJSON!
    ) {
      updateJobParagraphs(
        jobId: $jobId
        cognitoId: $cognitoId
        paragraphs: $paragraphs
      ) {
        accountId
        creationTime
        duration
        inputKey
        jobName
        languageCode
        name
        paragraphsKey
        paragraphs
        status
      }
    }
  `

  try {
    const operation = graphqlOperation(mutation, params)
    const response = await API.graphql(operation)
    return { job: response.data.updateJobParagraphs }
  } catch (err) {
    return { error: I18n.get('Failed to update job.') }
  }
}
