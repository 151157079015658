import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='108.47146mm'
      height='71.958046mm'
      viewBox='0 0 108.47146 71.958046'
      {...props}
    >
      <defs>
        <linearGradient id='a'>
          <stop offset={0} stopColor='#fff' stopOpacity={0.75369458} />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          xlinkHref='#a'
          x1={161.64371}
          y1={8.1204653}
          x2={162.87386}
          y2={31.001537}
          gradientUnits='userSpaceOnUse'
        />
      </defs>
      <g
        fill='#3f4757'
        fillOpacity={1}
        stroke='none'
        strokeLinejoin='round'
        strokeMiterlimit={4}
        strokeDasharray='none'
        strokeOpacity={1}
      >
        <path
          d='M67.828-23.404C56.997-25.192 43.109-28.82 40.13-39.64c-1.858-7.092-4.253-11.436-7.02-12.73-.741-.348-.866-.37-1.749-.31-.91.063-.96.053-1.19-.24-1.323-1.68-.357-6.527 2.234-11.211 3.247-5.87 8.335-11.466 13.742-15.114 6.92-4.668 15.109-6.72 25.765-6.456 10.068.345 21.698 2.103 28.693 8.719 4.08 3.95 6.1 8.707 6.57 15.467-.057 10.653-1.374 23.53-9.558 30.887-8.184 7.357-18.59 7.974-29.789 7.224z'
          opacity={0.957377}
          fill='#212529'
          strokeWidth={0.090797}
          transform='translate(-44.978 -40.7) translate(19.799 122.372) scale(.85122)'
        />
        <path
          d='M68.421-51.652c-.876-.942-.228-2.55 1.027-2.55.844 0 1.7.943 1.537 1.693-.268 1.229-1.756 1.726-2.564.857z'
          opacity={0.957377}
          fill='#3f4757'
          strokeWidth={0.133204}
          transform='translate(-44.978 -40.7) translate(19.799 122.372) scale(.85122)'
        />
        <path
          d='M94.12-51.635c-.875-.97-.2-2.51 1.099-2.51 1.3 0 1.974 1.54 1.099 2.51-.573.635-1.625.635-2.198 0z'
          opacity={0.957377}
          fill='#3f4757'
          strokeWidth={0.133636}
          transform='translate(-44.978 -40.7) translate(19.799 122.372) scale(.85122)'
        />
      </g>
      <g fill='#3f47ec' fillOpacity={1} strokeLinejoin='round'>
        <path
          d='M114.045 93.762c2.68-3.617 5.326-8.839 5.326-16.41 0-7.57-3.536-13.78-4.526-15.226l5.697-4.065c1.449 2.155 5.86 9.097 5.86 19.291 0 10.195-4.069 16.794-6.495 20.34z'
          opacity={1}
          strokeWidth={0.145296}
          transform='translate(-44.978 -40.7)'
        />
        <path
          d='M136.438 108.584c4.95-7.095 9.763-17.701 9.831-31.232-.596-13.164-3.702-22.407-9.429-30.817l5.813-4.235c6.423 9.423 10.55 20.712 10.79 35.052.234 13.959-5.463 27.188-11.111 35.306z'
          opacity={1}
          strokeWidth={0.145831}
          transform='translate(-44.978 -40.7)'
        />
        <path
          d='M125.246 101.15c4.978-6.957 7.792-15.357 7.519-23.678.14-8.898-2.518-16.719-6.813-23.126l5.853-4.22c5.629 8.386 8.224 17.84 8.231 27.226.007 9.425-3.027 19.56-8.833 27.84 0 0-2.817-1.865-5.957-4.043z'
          opacity={1}
          strokeWidth={0.143071}
          transform='translate(-44.978 -40.7)'
        />
      </g>
      <g
        transform='translate(-44.978 -40.7)'
        stroke='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={4}
        strokeDasharray='none'
      >
        <path
          d='M63.884 78.353c-.17-8.007 11.89-9.404 19.686-9.376 18.605.087 23.968 2.467 23.968 9.426 0 5.804-5.131 9.55-12.411 6.903-6.312-2.296-7.38-1.6-14.092-.274-11.59 2.858-17.044-1.587-17.151-6.68z'
          opacity={1}
          fill='#fff'
          fillOpacity={1}
          strokeWidth={2.08971}
          strokeOpacity={0.951417}
          paintOrder='markers fill stroke'
        />
        <circle
          cx={78.42498}
          cy={77.388351}
          r={2.3268704}
          fill='#212529'
          fillOpacity={0.96078432}
          strokeWidth={3.4}
          paintOrder='stroke markers fill'
        />
        <circle
          cx={100.42515}
          cy={77.801529}
          r={2.3268704}
          fill='#212529'
          fillOpacity={0.96078432}
          strokeWidth={3.4}
          paintOrder='stroke markers fill'
        />
      </g>
    </svg>
  )
}

export default SvgComponent
