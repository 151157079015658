import { I18n } from 'aws-amplify'

import history from '../../history'

const Benefits = () => (
  <section className='mt-4 mb-2 px-2 pt-3 pb-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-6 my-2'>
          <div className='px-3 py-2'>
            <h2 className='mb-3 text-black'>{I18n.get('Easy to use')}</h2>
            <p className='benefits-text'>
              {I18n.get(
                'Select your file*, target language and start transcribing.'
              )}
            </p>
          </div>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <div className='px-3 py-2'>
            <h2 className='mb-3 text-black'>{I18n.get('Customize')}</h2>
            <p className='benefits-text'>
              {I18n.get('Enable redaction** and/or detect speakers.')}
            </p>
          </div>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <div className='px-3 py-2'>
            <h2 className='mb-3 text-black'>
              {I18n.get('Multiple languages')}
            </h2>
            <p className='benefits-text'>
              English, Spanish, Portuguese, French and many{' '}
              <a href='/supported-languages' target='blank'>
                more
              </a>
              .
            </p>
          </div>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <div className='px-3 py-2'>
            <h2 className='mb-3 text-black'>{I18n.get('TL;DR')}</h2>
            <p className='benefits-text'>
              {I18n.get(
                'All transcripts come with timestamps and are stored*** for 90 days.'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center'>
          <button
            className='btn btn-primary landing-page-btn'
            type='button'
            onClick={() => history.push('/app')}
          >
            <strong>{I18n.get('Try for free')}</strong>
          </button>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-sm-12'>
          <small>
            *Supported file formats: <strong>mp3</strong>, <strong>flac</strong>
            , <strong>wav</strong> and <strong>mp4</strong>.
          </small>
        </div>
        <div className='col-sm-12'>
          <small>
            **Redaction is only available when <strong>US English</strong> is
            the target language.
          </small>
        </div>
        <div className='col-sm-12'>
          <small>
            ***Transcripts get <strong>automatically</strong> deleted after 90
            days.
          </small>
        </div>
      </div>
    </div>
  </section>
)

export default Benefits
